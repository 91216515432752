import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import {
  createGesture,
  Gesture,
  GestureDetail,
  IonModal,
  IonRouterLink,
  IonSkeletonText,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import ReactInstaStories from 'react-insta-stories';
import { Story as ReactInstaStory } from 'react-insta-stories/dist/interfaces';

import { Story, StoryPage } from '../../api/interfaces/story';
import { addStoryPageLinkView, addStoryPageView, getStories } from '../../api/stories';
import useWindowSize from '../../hooks/window-size-hook';
import { isApiError } from '../../utils/api-util';
import { wait } from '../../utils/misc-util';
import B1Button from '../buttons/B1Button';
import B1Modal from '../modal/B1Modal';
import StoryVideoPlayer from '../video-player/StoryVideoPlayer';

import './Stories.scss';

const Stories: React.FC = () => {
  const ionRouter = useIonRouter();
  const { width } = useWindowSize();

  const modal = useRef<HTMLIonModalElement | null>(null);
  const seeMoreRef = useRef<HTMLDivElement | null>(null);
  const storyThumbnailRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();

  const [storyModalOpen, setStoryModalOpen] = useState(false);
  const [storyLinkModalOpen, setStoryLinkModalOpen] = useState(false);
  const [stories, setStories] = useState<Story[]>([]);
  const [storiesLoading, setStoriesLoading] = useState(true);
  const [selectedStory, setSelectedStory] = useState<Story | null>(null);
  const [currentStoryPageIndex, setCurrentStoryPageIndex] = useState(0);

  let swipeUpGesture: Gesture | undefined = undefined;
  let swipeUpEnded = false;

  useIonViewWillEnter(() => {
    getStories().then((res) => {
      if (isApiError(res)) {
        console.log(res);
      } else {
        setStories(res);
        setStoriesLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    Device.getInfo().then((info) => {
      setDeviceInfo(info);
    });
  }, []);

  useEffect(() => {
    storyThumbnailRefs.current.forEach((ref) => {
      if (ref) {
        const imageUrl = ref.getAttribute('data-bg-image');

        if (imageUrl) {
          ref.style.setProperty('--bg-image', `url(${imageUrl})`);
        }
      }
    });
  }, [stories]);

  const isInternalLink = (link?: string) => {
    return link?.includes('bootsschule1.app') || link?.startsWith('/');
  };

  const getInternalLink = (link?: string) => {
    return link?.split('bootsschule1.app').pop();
  };

  const closeStoryModal = () => {
    if (selectedStory) {
      selectedStory.pages.forEach((page, index) => {
        if (index <= currentStoryPageIndex) {
          page.viewed = true;
        }
      });
    }

    setStoryModalOpen(false);
    setSelectedStory(null);
  };

  const getStoryPages = (story: Story) => {
    const pages: ReactInstaStory[] = story.pages.map((page) => ({
      url:
        page.type === 'picture'
          ? page.picture_path
          : page.type === 'video'
            ? page.video?.hls_link
            : undefined,
      type: page.type === 'picture' ? 'image' : page.type === 'video' ? 'video' : undefined,
      preloadResource: true,
      duration: (page.duration ?? 10) * 1000,
      seeMore: () => <></>,
      seeMoreCollapsed: ({ action }) => {
        if (!page.link) return <></>;

        page.action = action;

        return (
          <div ref={seeMoreRef}>
            {isInternalLink(page.link) ? (
              <IonRouterLink
                className='see-more'
                routerLink={getInternalLink(page.link)}
                onClick={() => {
                  action('pause');
                  closeStoryModal();
                  addStoryPageLinkView(page.id);
                }}
              >
                <div className='see-more-inner'>
                  <BsChevronUp />
                  Mehr erfahren
                </div>
              </IonRouterLink>
            ) : (
              <a
                className='see-more'
                href={page.link}
                target='_blank'
                rel='noreferrer'
                onClick={async (e) => {
                  action('pause');
                  addStoryPageLinkView(page.id);

                  if (Capacitor.isNativePlatform() && page.link) {
                    e.preventDefault();

                    const deviceInfo = await Device.getInfo();

                    await Browser.open({
                      url: page.link,
                      windowName: '_blank',
                      presentationStyle: deviceInfo.model.includes('iPad')
                        ? 'fullscreen'
                        : 'popover',
                    });

                    Browser.addListener('browserFinished', () => {
                      action('play');
                    });
                  }

                  e.currentTarget.blur();
                }}
              >
                <div className='see-more-inner'>
                  <BsChevronUp />
                  Mehr erfahren
                </div>
              </a>
            )}
          </div>
        );
      },
    }));

    return pages;
  };

  const logStoryPageView = (story: Story, pageIndex: number) => {
    setCurrentStoryPageIndex(pageIndex);

    const page = story.pages[pageIndex];

    addStoryPageView(page.id);

    if (modal.current) {
      const target = modal.current.querySelector('.ion-page');

      if (target) {
        if (swipeUpGesture) {
          swipeUpGesture.destroy();
        }

        swipeUpGesture = createGesture({
          el: target,
          onStart: () => onSwipeUpStart(),
          onMove: (detail) => onSwipeUpMove(detail, page),
          onEnd: () => onSwipeUpEnd(),
          gestureName: 'swipe-up',
          direction: 'y',
        });

        swipeUpGesture.enable();
      }
    }
  };

  // const setStoryPageViewed = (story: Story, pageIndex: number) => {
  //   story.pages[pageIndex].viewed = true;
  // };

  const getStoryPageIndex = (story: Story) => {
    const index = story.pages.findIndex((page) => !page.viewed);

    if (index === -1) return 0;

    return index;
  };

  const onSwipeUpStart = () => {
    swipeUpEnded = false;
  };

  const onSwipeUpMove = (detail: GestureDetail, page?: StoryPage) => {
    const { deltaY } = detail;

    if (seeMoreRef.current && deltaY < 0) {
      seeMoreRef.current.style.transform = `translateY(${deltaY}px)`;
    }

    if (deltaY < -200 && !swipeUpEnded) {
      swipeUpEnded = true;

      if (page?.link) {
        addStoryPageLinkView(page.id);

        if (isInternalLink(page.link)) {
          const internalLink = getInternalLink(page.link);

          if (internalLink) {
            ionRouter.push(internalLink);
            closeStoryModal();
          }
        } else {
          if (page.action) {
            page.action('pause');
          }

          if (Capacitor.isNativePlatform()) {
            Browser.open({
              url: page.link,
              windowName: '_blank',
              presentationStyle: deviceInfo?.model.includes('iPad') ? 'fullscreen' : 'popover',
            }).then(() => {
              Browser.addListener('browserFinished', () => {
                if (page.action) {
                  page.action('play');
                }
              });
            });
          } else {
            setStoryLinkModalOpen(true);
          }
        }

        swipeUpGesture?.enable(false);

        wait(500).then(() => {
          swipeUpGesture?.enable(true);
        });
      }
    }
  };

  const onSwipeUpEnd = () => {
    swipeUpEnded = false;

    if (seeMoreRef.current) {
      seeMoreRef.current.style.transform = '';
    }
  };

  return (
    <div className='stories-container'>
      <div className='stories'>
        {!storiesLoading &&
          stories.map((story, index) => (
            <div
              key={index}
              className={`story-border scale-effect${story.pages.every((p) => p.viewed) ? ' viewed' : ''}`}
            >
              <div
                ref={(el) => (storyThumbnailRefs.current[index] = el)}
                className='story-thumbnail'
                data-bg-image={story.thumbnail_path ?? '/assets/images/stories/boot.jpeg'}
                onClick={() => {
                  if (story.pages.length > 0) {
                    setSelectedStory(story);
                    setStoryModalOpen(true);
                  }
                }}
              >
                <h3>{story.title}</h3>
              </div>
            </div>
          ))}
        {storiesLoading && (
          <div className='story-border scale-effect viewed'>
            <div className='story-thumbnail'>
              <h3>
                <IonSkeletonText animated style={{ width: '60%', height: '32px' }} />
                <IonSkeletonText animated style={{ width: '80%', height: '32px' }} />
                <IonSkeletonText animated style={{ width: '40%', height: '32px' }} />
              </h3>
            </div>
          </div>
        )}
      </div>

      <IonModal
        ref={modal}
        id='story-modal'
        isOpen={storyModalOpen}
        onDidDismiss={() => closeStoryModal()}
      >
        <button type='button' className='close-button' onClick={() => closeStoryModal()}>
          <img src='assets/icons/close-icon-grey.svg' />
        </button>
        {selectedStory && selectedStory.pages.length > 0 && (
          <ReactInstaStories
            stories={getStoryPages(selectedStory)}
            defaultInterval={10000}
            width={(width ?? 0) < 576 ? '100vw' : 432}
            height={(width ?? 0) < 576 ? '100%' : 768}
            renderers={[StoryVideoPlayer]}
            isPaused={true}
            onAllStoriesEnd={() => closeStoryModal()}
            onStoryStart={(page: number) => logStoryPageView(selectedStory, page)}
            // onStoryEnd={(page: number) => setStoryPageViewed(selectedStory, page)}
            currentIndex={getStoryPageIndex(selectedStory)}
            progressWrapperStyles={{
              marginTop: 'var(--safe-area-inset-top, 0)',
            }}
          />
        )}
      </IonModal>

      <B1Modal
        className='story-link-modal'
        open={storyLinkModalOpen}
        onModalClose={() => {
          setStoryLinkModalOpen(false);
          const action = selectedStory?.pages[currentStoryPageIndex]?.action;

          if (action) action('play');
        }}
      >
        <p className='mb-3'>Möchtest Du mehr Informationen erhalten?</p>
        {isInternalLink(selectedStory?.pages[currentStoryPageIndex]?.link) ? (
          <B1Button
            href={getInternalLink(selectedStory?.pages[currentStoryPageIndex]?.link)}
            click={() => setStoryLinkModalOpen(false)}
          >
            Mehr erfahren
          </B1Button>
        ) : (
          <B1Button
            href={selectedStory?.pages[currentStoryPageIndex]?.link}
            external
            click={() => setStoryLinkModalOpen(false)}
          >
            Mehr erfahren
          </B1Button>
        )}
      </B1Modal>
    </div>
  );
};

export default Stories;
