import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import {
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonToolbar,
  useIonLoading,
  useIonRouter,
  useIonToast,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import axios from 'axios';
import { downloadOutline, refreshOutline } from 'ionicons/icons';
import JSZip from 'jszip';
import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useParams } from 'react-router';

import { getCourseDocuments } from '../../api/courses/documents';
import { getCourse, resetCourseProgress } from '../../api/courses/learn-mode';
import { AccountStatus } from '../../api/interfaces/account-info';
import Course, { Chapter } from '../../api/interfaces/course';
import B1Button from '../../components/buttons/B1Button';
import B1Modal from '../../components/modal/B1Modal';
import ProgressBar from '../../components/progressbar/ProgressBar';
import { useKeyboard } from '../../hooks/keyboard-hook';
import { AuthContext } from '../../providers/AuthProvider';
import { isApiError } from '../../utils/api-util';
import { getCourseIcon, getMainCourse, getShortCourseName } from '../../utils/course-util';
import { enableSafeArea } from '../../utils/misc-util';
import Layout from '../Layout';
import Sidebar from './components/Sidebar';

import './Learnmode.scss';

interface LearnmodeProps extends PropsWithChildren {
  onCourseLoaded?: (course: Course) => void;
}

const Learnmode: React.FC<LearnmodeProps> = ({ children, onCourseLoaded }) => {
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const { identifier, chapterId } = useParams<{ identifier: string; chapterId?: string }>();
  const { isAuthenticated, getUserData } = useContext(AuthContext);
  const [userState, setUserState] = useState<AccountStatus>();
  const [useModal, setUseModal] = useState(window.innerWidth < 576);
  const [chapterSelectionModalOpen, setChapterSelectionModalOpen] = useState(false);
  const [resetProgressModalOpen, setResetProgressModalOpen] = useState(false);
  const [course, setCourse] = useState<Course>();

  const pageRef = useRef<HTMLElement>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const ionRouter = useIonRouter();
  const { isOpen: isKeyboardOpen } = useKeyboard();

  const [presentingElement, setPresentingElement] = useState<HTMLElement>();

  useIonViewWillEnter(() => {
    enableSafeArea('dark');
  });

  useIonViewWillLeave(() => {
    enableSafeArea('light');
  });

  useEffect(() => {
    getUserData().then(setUserState);
  }, [isAuthenticated, getUserData]);

  const loadCourse = async () => {
    getCourse(identifier).then((res) => {
      if (isApiError(res)) {
        ionRouter.push('/', 'back', 'replace');
        console.error(res);
      } else {
        setCourse(res);

        if (onCourseLoaded) {
          onCourseLoaded(res);
        }
      }
    });
  };

  useIonViewWillEnter(() => {
    loadCourse();
  }, [identifier, chapterId]);

  useEffect(() => {
    setPresentingElement(pageRef.current ?? undefined);
  }, [pageRef]);

  // close the sidebar when the window is resized and open it when the window is big enough
  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth >= 576) {
        setUseModal(false);
        setChapterSelectionModalOpen(false);
      } else {
        setUseModal(true);
      }
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const handleChapterSelected = async (chapter: Chapter, closeModal?: boolean) => {
    ionRouter.push(`/course/${identifier}/learnmode/${chapter.id}`);

    if (closeModal) {
      setChapterSelectionModalOpen(false);
    }
  };

  const handleResetProgress = async () => {
    const result = await resetCourseProgress(identifier);

    if (result === true) {
      loadCourse();
    } else {
      console.error(result);
    }

    setResetProgressModalOpen(false);

    // TODO: Add success toast
  };

  const downloadAllDocuments = async () => {
    present({
      message: 'Dokumente werden heruntergeladen...',
      duration: 0,
      mode: Capacitor.getPlatform() === 'ios' ? 'ios' : 'md',
    });

    getCourseDocuments(identifier).then(async (res) => {
      if (isApiError(res)) {
        console.error(res);

        dismiss();
        presentToast({
          message:
            'Beim Herunterladen der Dokumente ist ein Fehler aufgetreten.\n' +
            'Bitte versuche es später erneut oder melde Dich bei uns.',
          duration: 5000,
          position: 'bottom',
          color: 'danger',
        });
        return;
      }

      // Download all documents and zip them
      const zip = new JSZip();

      for (let i = 0; i < res.length; i++) {
        const path = res[i].path;
        const name = path.split('/').pop();

        if (!name) continue;

        try {
          const response = await axios.get(path, { responseType: 'blob', withCredentials: true });
          zip.file(name, response.data, { binary: true });
        } catch (err) {
          console.error(`Error downloading document "${name}":`, err);
        }
      }

      if (Object.keys(zip.files).length === 0) {
        dismiss();
        presentToast({
          message: 'Es wurden keine Dokumente gefunden, die heruntergeladen werden können.',
          duration: 3000,
          position: 'bottom',
          color: 'warning',
        });
        return;
      }

      let fileName = getShortCourseName(identifier).replace(/ /g, '_');

      if (!fileName) {
        fileName = identifier.toUpperCase();
      }

      fileName += '-Dokumente.zip';

      zip
        .generateAsync({ type: Capacitor.isNativePlatform() ? 'base64' : 'blob' })
        .then(async (content) => {
          if (typeof content === 'string') {
            const res = await Filesystem.writeFile({
              path: 'courses/documents/' + fileName,
              data: content,
              directory: Directory.Cache,
              recursive: true,
            });

            await FileOpener.openFile({
              path: res.uri,
              mimeType: 'application/zip',
            });
          } else {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(content);
            a.download = fileName;
            a.click();
          }

          dismiss();
        })
        .catch((err) => {
          console.error('Error creating zip file:', err);

          dismiss();
          presentToast({
            message:
              'Beim Herunterladen der Dokumente ist ein Fehler aufgetreten.\n' +
              'Bitte versuche es später erneut oder melde Dich bei uns.',
            duration: 5000,
            position: 'bottom',
            color: 'danger',
          });
        });
    });
  };

  return (
    <Layout
      contentClassName='learnmode'
      pageRef={pageRef}
      backButton={'/course/' + getMainCourse(identifier)}
      currentCourseType={identifier}
      moreInfoContent={
        <>
          <IonItem
            button
            detail={false}
            lines='none'
            onClick={() => setResetProgressModalOpen(true)}
          >
            <IonIcon icon={refreshOutline} slot='start' />
            <IonLabel class='ion-text-wrap'>Fortschritt zurücksetzen</IonLabel>
          </IonItem>
          {course?.has_documents && (
            <IonItem button detail={false} lines='none' onClick={() => downloadAllDocuments()}>
              <IonIcon icon={downloadOutline} slot='start' />
              <IonLabel class='ion-text-wrap'>Dokumente herunterladen</IonLabel>
            </IonItem>
          )}
        </>
      }
      footer={
        useModal &&
        !isKeyboardOpen && (
          <IonFooter className='learnmode-lesson-footer ion-no-border'>
            <IonToolbar className='learnmode-lesson-toolbar'>
              <button
                type='button'
                className='learnmode-lesson-modal-button open-modal'
                onClick={() => setChapterSelectionModalOpen(true)}
              >
                <img src={getCourseIcon(course?.identifier)} />
                <span>{getShortCourseName(course?.identifier)}</span>
                <BiChevronUp />
              </button>
            </IonToolbar>
          </IonFooter>
        )
      }
    >
      <div className='learnmode-container'>
        {!useModal && (
          <Sidebar
            course={course}
            selectedChapter={Number(chapterId)}
            onChapterSelected={handleChapterSelected}
          />
        )}
        <div className='learnmode-content custom-scrollbar'>
          <div className='lesson-progress'>
            <ProgressBar progress={course?.progress ?? 0} />
            <span>{((course?.progress ?? 0) * 100).toFixed(0)}% abgeschlossen</span>
          </div>
          {children}
        </div>
      </div>
      <IonModal
        ref={modal}
        presentingElement={presentingElement}
        mode='ios'
        className='learnmode-lesson-modal'
        isOpen={chapterSelectionModalOpen}
        onIonModalWillPresent={() => enableSafeArea('light')}
        onIonModalWillDismiss={() => enableSafeArea('dark')}
        onIonModalDidDismiss={() => setChapterSelectionModalOpen(false)}
      >
        <IonContent>
          <Sidebar
            course={course}
            selectedChapter={Number(chapterId)}
            modal
            onChapterSelected={(chapter) => handleChapterSelected(chapter, true)}
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <button
              type='button'
              onClick={() => setChapterSelectionModalOpen(false)}
              className='learnmode-lesson-modal-button close-modal'
            >
              <img src={getCourseIcon(course?.identifier)} />
              <span>{getShortCourseName(course?.identifier)}</span>
              <BiChevronDown />
            </button>
          </IonToolbar>
        </IonFooter>
      </IonModal>

      <B1Modal
        className='reset-progress-modal'
        open={resetProgressModalOpen}
        onModalClose={() => setResetProgressModalOpen(false)}
      >
        <h2 className='mb-3'>Fortschritt zurücksetzen</h2>
        <h4 className='mb-5'>
          {userState?.firstname}, bist Du Dir sicher, dass Du Deinen gesamten Fortschritt im
          Lernmodus dieses Kurses löschen möchtest?
        </h4>
        <div className='reset-progress-buttons mb-5'>
          <B1Button className='button-block button-green' click={() => handleResetProgress()}>
            Ja
          </B1Button>
          <B1Button
            className='button-block button-red'
            click={() => setResetProgressModalOpen(false)}
          >
            Nein
          </B1Button>
        </div>
        <p className='description mb-0'>Du kannst diesen Schritt nicht rückgängig machen.</p>
      </B1Modal>
    </Layout>
  );
};

export default Learnmode;
