import { Capacitor } from '@capacitor/core';
import { useIonToast, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';

import { getAccountInfo } from '../../api/account/account';
import { AccountInfo } from '../../api/interfaces/account-info';
import {
  ClaimedSpin,
  ClaimedSpinPrize,
  isRedeemedSpinPrize,
  RedeemedSpin,
  RedeemedSpinPrize,
  RedeemedSpinPrizeDiscount,
  WheelSpinPrizeOption,
  WheelSpins,
} from '../../api/interfaces/wheel-spins';
import { getWheelSpinPrizes, getWheelSpins, redeemWheelSpin } from '../../api/wheel-spins';
import B1Button from '../../components/buttons/B1Button';
import CustomLink from '../../components/custom-link/CustomLink';
import B1Modal from '../../components/modal/B1Modal';
import ChangeAddressModal from '../../components/modal/change-address-modal/ChangeAddressModal';
import ReferralModal from '../../components/modal/referral-modal/ReferralModal';
import useWindowSize from '../../hooks/window-size-hook';
import { isApiError } from '../../utils/api-util';
import { SHORT_DATE_FORMAT } from '../../utils/date-util';
import Layout from '../Layout';
import ColorSizeChooser from './components/ColorSizeChooser';
import PreviousWinCard from './components/PreviousWinCard';
import WheelOfFortune from './components/WheelOfFortune';

import './Gluecksrad.scss';

const Gluecksrad: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [presentToast] = useIonToast();
  const pageRef = useRef<HTMLElement>(null);
  const [presentingElement, setPresentingElement] = useState<HTMLElement>();
  const { width: windowWidth } = useWindowSize();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const [wheelSpins, setWheelSpins] = useState<WheelSpins>();
  const [wheelSpinPrizes, setWheelSpinPrizes] = useState<WheelSpinPrizeOption[]>([]);
  const [referralModalOpen, setReferralModalOpen] = useState(false);
  const [previousWinsModalOpen, setPreviousWinsModalOpen] = useState(false);
  const [redeemModalOpen, setRedeemModalOpen] = useState(false);
  const [changeAddressModalOpen, setChangeAddressModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const [selectedSpinId, setSelectedSpinId] = useState<number>();
  const [selectedPrize, setSelectedPrize] = useState<ClaimedSpinPrize | RedeemedSpinPrize>();
  const [selectedColorId, setSelectedColor] = useState<number>();
  const [selectedSizeId, setSelectedSize] = useState<number>();

  const [loading, setLoading] = useState(false);

  useIonViewWillEnter(() => {
    getAccountInfo().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setAccountInfo(res);
    });

    getWheelSpins().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setWheelSpins(res);
    });

    getWheelSpinPrizes().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setWheelSpinPrizes(res);
    });
  }, []);

  useIonViewDidEnter(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
        setHeight(containerRef.current.offsetHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setPresentingElement(pageRef?.current ?? undefined);
  }, [pageRef]);

  const unclaimedValidSpins = wheelSpins?.unclaimedSpins.filter((spin) => {
    return DateTime.fromISO(spin.valid_until) > DateTime.now();
  });

  const handleClaimedSpin = (spinId: number, result: ClaimedSpinPrize) => {
    setSelectedSpinId(spinId);
    setSelectedPrize(result);
    setSelectedSize(undefined);
    setSelectedColor(undefined);

    setRedeemModalOpen(true);

    getWheelSpins().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setWheelSpins(res);
    });
  };

  const openRedeemModal = (spin: ClaimedSpin | RedeemedSpin) => {
    setSelectedSpinId(spin.id);
    setSelectedPrize(spin.prize);
    setSelectedSize(undefined);
    setSelectedColor(undefined);

    setPreviousWinsModalOpen(false);
    setRedeemModalOpen(true);
  };

  const checkDataToRedeemSpin = () => {
    if (selectedSpinId === undefined || !selectedPrize) {
      console.error('No spin selected to redeem');
      return;
    }

    if (isRedeemedSpinPrize(selectedPrize)) {
      console.error('Spin already redeemed');
      return;
    }

    if (selectedPrize.type === 'print') {
      const colors = selectedPrize.info?.colors;

      if (colors) {
        let tempColor;
        let tempSize;

        const isAvailable = (color: { id: number; sizes: { available: boolean }[] }) => {
          return color.sizes.every((s) => s.available);
        };

        const selectedColor = colors.find((c) => c.id === selectedColorId);
        const firstAvailableColor = colors.find((c) => isAvailable(c));

        if (selectedColor && isAvailable(selectedColor)) {
          tempColor = selectedColor;
        } else if (firstAvailableColor) {
          tempColor = firstAvailableColor;
        }

        if (!tempColor) {
          presentToast({
            message: 'Bitte wähle eine Farbe aus.',
            duration: 3000,
            position: 'bottom',
            color: 'warning',
          });
          return;
        } else if (tempColor.sizes.every((s) => !s.available)) {
          presentToast({
            message: 'Die gewählte Farbe ist nicht verfügbar.',
            duration: 3000,
            position: 'bottom',
            color: 'warning',
          });
          return;
        }

        const selectedSize = tempColor.sizes.find((s) => s.id === selectedSizeId);
        const availableSizes = tempColor.sizes.filter((s) => s.available);

        if (selectedSize) {
          tempSize = selectedSize;
        } else if (availableSizes.length === 1) {
          tempSize = availableSizes[0];
        }

        if (!tempSize) {
          presentToast({
            message: 'Bitte wähle eine Größe aus.',
            duration: 3000,
            position: 'bottom',
            color: 'warning',
          });
          return;
        } else if (!tempSize.available) {
          presentToast({
            message: 'Die gewählte Größe ist nicht verfügbar.',
            duration: 3000,
            position: 'bottom',
            color: 'warning',
          });
          return;
        }

        setSelectedColor(tempColor.id);
        setSelectedSize(tempSize.id);
      }

      setChangeAddressModalOpen(true);
      return;
    } else if (selectedPrize.type === 'course') {
      setChangeAddressModalOpen(true);
      return;
    }

    redeemSpin();
  };

  const handleAddressChanged = () => {
    setChangeAddressModalOpen(false);
    setRedeemModalOpen(true);
    redeemSpin();
  };

  const redeemSpin = () => {
    if (selectedSpinId === undefined) {
      console.error('No spin selected to redeem');
      return;
    }

    setLoading(true);

    redeemWheelSpin(selectedSpinId, selectedSizeId, selectedColorId).then((res) => {
      setLoading(false);

      if (isApiError(res)) {
        presentToast({
          message:
            'Leider ist beim beim Einlösen Deines Gewinns ein Fehler aufgetreten.\n' +
            'Bitte versuche es später erneut oder melde Dich bei uns.',
          duration: 5000,
          position: 'bottom',
          color: 'danger',
        });
        return;
      }

      presentToast({
        message: 'Dein Gewinn wurde erfolgreich eingelöst!',
        duration: 5000,
        position: 'bottom',
        color: 'success',
      });

      getWheelSpins().then((res) => {
        if (isApiError(res)) {
          console.error(res);
          return;
        }

        setWheelSpins(res);

        const redeemed = res.redeemedSpins.find((spin) => spin.id === selectedSpinId);

        if (redeemed) {
          setSelectedPrize(redeemed.prize);
        }
      });
    });
  };

  const formatDiscount = (prize: RedeemedSpinPrizeDiscount) => {
    switch (prize.info?.discount_type) {
      case 'percent':
        return `${Math.round(Number(prize.info.discount))} %`;
      case 'fixed_cart':
      case 'fixed_product':
        return new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: 'EUR',
        }).format(Number(prize.info.discount));
      default:
        return '';
    }
  };

  return (
    <Layout pageRef={pageRef} backButton='/account'>
      <div className='wheel-of-fortune-container'>
        <h1 className='mt-4'>🤩 Gewinne exklusive Prämien</h1>
        <div className='share-container'>
          <div className='share-text'>
            <span>
              Dein Freund erhält <strong>30 EUR</strong> Rabatt & Du darfst pro geworbenem Freund
              einmal das Glücksrad drehen.&nbsp;
            </span>
            <div className='share-code'>
              <span className='share-code-label'>Dein Code:&nbsp;</span>
              <span className='share-code'>{accountInfo?.user.kwk_coupon}</span>
            </div>
          </div>
          <B1Button className='share-button' click={() => setReferralModalOpen(true)}>
            Link teilen
          </B1Button>
        </div>
        <div className='wheel-wrapper'>
          <div ref={containerRef} className='wheel-container'>
            <WheelOfFortune
              width={width}
              height={height}
              rewards={wheelSpinPrizes}
              unclaimedSpins={unclaimedValidSpins}
              onSpinningFinished={handleClaimedSpin}
            />
          </div>
          <div className='wheel-info'>
            <h5 className='mb-3 mt-4'>Übersicht</h5>
            <div className='remaining-spins'>
              <span>Verbleibende Versuche</span>
              <span className='spins'>{unclaimedValidSpins?.length ?? 0}</span>
            </div>
            <div className='invited-friends'>
              <span>Geworbene Freunde</span>
              <span className='friends'>
                {(wheelSpins?.unclaimedSpins.length ?? 0) +
                  (wheelSpins?.claimedSpins.length ?? 0) +
                  (wheelSpins?.redeemedSpins.length ?? 0)}
              </span>
            </div>

            <B1Button
              className='button-reverse button-block mt-4 mb-4'
              click={() => setReferralModalOpen(true)}
            >
              Freunde werben
            </B1Button>

            {((wheelSpins?.claimedSpins.length ?? 0) > 0 ||
              (wheelSpins?.redeemedSpins.length ?? 0) > 0) && (
              <B1Button
                className='button-obscure button-block mb-4'
                click={() => setPreviousWinsModalOpen(true)}
              >
                Bisherige Gewinne
              </B1Button>
            )}

            <div className='legal-links'>
              <CustomLink onClick={() => setTermsModalOpen(true)}>Teilnahmebedingungen</CustomLink>
            </div>
          </div>
        </div>

        <div className='legal-links'>
          <CustomLink onClick={() => setTermsModalOpen(true)}>Teilnahmebedingungen</CustomLink>
        </div>
      </div>

      <ReferralModal
        open={referralModalOpen}
        setOpen={setReferralModalOpen}
        accountInfo={accountInfo}
      />

      <B1Modal
        className='previous-wins-modal'
        open={previousWinsModalOpen}
        onModalClose={() => setPreviousWinsModalOpen(false)}
      >
        <h2 className='mb-4'>Bisherige Gewinne</h2>

        <div className='previous-wins'>
          {wheelSpins?.claimedSpins.map((spin) => (
            <PreviousWinCard key={spin.id} spin={spin} onButtonClick={openRedeemModal} />
          ))}

          {(wheelSpins?.redeemedSpins.length ?? 0) > 0 && (
            <>
              <hr className='mt-5' />
              <h5 className='mt-3 mb-3'>Eingelöste Gewinne</h5>

              {wheelSpins?.redeemedSpins.map((spin) => (
                <PreviousWinCard key={spin.id} spin={spin} onButtonClick={openRedeemModal} />
              ))}
            </>
          )}
        </div>
      </B1Modal>

      <B1Modal
        className='redeem-modal modal-white'
        open={redeemModalOpen}
        onModalClose={() => setRedeemModalOpen(false)}
        enableAutoHeight
      >
        <h1 className='mb-2'>🎉</h1>
        <h2 className='mb-4'>Dein Gewinn: {selectedPrize?.title.replace('|', '')}</h2>

        {selectedPrize?.picture && (
          <img src={selectedPrize.picture} className='mb-4 spin-prize-picture' />
        )}

        {selectedPrize?.description && <p className='mb-4'>{selectedPrize?.description}</p>}

        {selectedPrize && !isRedeemedSpinPrize(selectedPrize) ? (
          <>
            <ColorSizeChooser
              prize={selectedPrize}
              selectedColorId={selectedColorId}
              selectedSizeId={selectedSizeId}
              onColorChange={setSelectedColor}
              onSizeChange={setSelectedSize}
            />

            <B1Button click={() => checkDataToRedeemSpin()} loading={loading}>
              Jetzt einlösen
            </B1Button>
          </>
        ) : (
          selectedPrize?.info &&
          (selectedPrize?.type === 'course' ? (
            <p>
              {selectedPrize.info.length === 1 ? 'Dein Kurs' : 'Deine Kurse'}:{' '}
              {selectedPrize.info
                .map((i) => {
                  let text = i.identifier;

                  if (i.valid_until) {
                    text += ` (gültig bis ${DateTime.fromISO(i.valid_until).toLocaleString(SHORT_DATE_FORMAT)})`;
                  }

                  return text;
                })
                .join(', ')}
            </p>
          ) : selectedPrize?.type === 'print' ? (
            <>
              <p>Dein Auftragsstatus: {selectedPrize.info.status}</p>
              {selectedPrize.info.shipping_link && (
                <B1Button href={selectedPrize.info.shipping_link} external className='mt-3'>
                  Versandstatus ansehen
                </B1Button>
              )}
            </>
          ) : selectedPrize?.type === 'charter_code' ? (
            <>
              <p>
                Dein Rabattgutschein: <strong>{selectedPrize.info.code}</strong>
              </p>
              {selectedPrize.info.valid_until && (
                <p>
                  Einlösbar bis:{' '}
                  {DateTime.fromISO(selectedPrize.info.valid_until).toLocaleString(
                    SHORT_DATE_FORMAT
                  )}
                </p>
              )}
              {selectedPrize.info.link && (
                <p className='mt-4'>
                  <B1Button href={selectedPrize.info.link} external>
                    Hier einlösen
                  </B1Button>
                </p>
              )}
            </>
          ) : selectedPrize?.type === 'discount' ? (
            <>
              <p>
                Dein Rabatt-Gutschein von {formatDiscount(selectedPrize)}:{' '}
                <strong>{selectedPrize.info.code.toUpperCase()}</strong>
              </p>
              {selectedPrize.info.valid_until && (
                <p>
                  Einlösbar bis:{' '}
                  {DateTime.fromISO(selectedPrize.info.valid_until).toLocaleString(
                    SHORT_DATE_FORMAT
                  )}
                </p>
              )}
              <p className='mt-4'>
                <B1Button href={selectedPrize.info.link} external>
                  Hier einlösen
                </B1Button>
              </p>
            </>
          ) : (
            <></>
          ))
        )}
      </B1Modal>

      <ChangeAddressModal
        open={changeAddressModalOpen}
        setOpen={setChangeAddressModalOpen}
        onAddressChanged={handleAddressChanged}
      />

      <B1Modal
        className='terms-modal'
        open={termsModalOpen}
        onModalClose={() => setTermsModalOpen(false)}
        enableAutoHeight
        hideCloseButton={windowWidth !== undefined && windowWidth < 576}
        showFooterCloseButton={windowWidth !== undefined && windowWidth < 576}
        mode={windowWidth !== undefined && windowWidth < 576 ? 'ios' : undefined}
        presentingElement={
          windowWidth !== undefined && windowWidth < 576 ? presentingElement : undefined
        }
      >
        <h2 className='mb-4'>TEILNAHMEBEDINGUNGEN FÜR DAS GLÜCKSRAD</h2>

        <h5>1. Veranstalter</h5>
        <p>
          Die Boot1 GmbH, Machandelweg 1 A, 29525 Uelzen (nachfolgend „Bootsschule1“) veranstaltet
          ein Gewinnspiel in Form eines digitalen Glücksrades. Dieses Glücksrad kann im Rahmen
          unserer Empfehlungsaktion gedreht werden. Für jeden neu geworbenen Freund, der sich über
          Ihren Empfehlungscode auf unserer Plattform registriert, erhalten Sie einen Spin auf dem
          Glücksrad.
        </p>
        <p>
          Mit der Teilnahme am Gewinnspiel erklären Sie sich mit den nachstehenden
          Teilnahmebedingungen einverstanden.
        </p>
        {Capacitor.getPlatform() === 'ios' && (
          <p>
            <strong>
              Apple Inc. ist kein Sponsor, Befürworter oder Organisator dieses Gewinnspiels und
              steht in keiner Weise mit diesem in Verbindung.
            </strong>
          </p>
        )}

        <h5>2. Teilnahme</h5>
        <p>Die Teilnahme am Gewinnspiel ist kostenlos und setzt folgende Bedingungen voraus:</p>
        <ol>
          <li>
            Die teilnehmende Person ist eine natürliche Person und hat das 18. Lebensjahr vollendet.
          </li>
          <li>Die teilnehmende Person hat ihren Wohnsitz in der Bundesrepublik Deutschland.</li>
          <li>
            Die teilnehmende Person wirbt mindestens einen neuen Nutzer (Freund) über ihren
            Empfehlungscode, welcher sich erfolgreich auf unserer Lernplattform registriert.
          </li>
          <li>
            Pro geworbenen Freund erhält die teilnehmende Person einen Spin auf dem Glücksrad.
          </li>
          <li>
            Die teilnehmende Person akzeptiert die Teilnahmebedingungen und gibt ihre E-Mail-Adresse
            an.
          </li>
        </ol>

        <h5>3. Gewinn</h5>
        <p>
          Durch das Drehen am Glücksrad können verschiedene Gewinne erspielt werden, die zufällig
          ausgelost werden. Die Gewinne umfassen:
        </p>
        <ul>
          <li>Quicksilver – Activ 455 Open</li>
          <li>10% Gutschein</li>
          <li>30% Gutschein</li>
          <li>60% Gutschein</li>
          <li>B1-T-Shirt (gedruckt)</li>
          <li>B1-T-Shirt (gestickt)</li>
          <li>B1-Kissen (40x40cm)</li>
          <li>B1-Kaffeetasse</li>
          <li>B1-Thermosflasche</li>
          <li>Online-Kurs zum Funkschein Kombi-Paket (exkl. Prüfungsgebühren)</li>
          <li>Online-Kurs zum Fachkundenachweis (FKN) (exkl. Prüfungsgebühren)</li>
          <li>Online-Kurs zum SBF Binnen Segel (exkl. Prüfungsgebühren)</li>
          <li>50€ Chartergutschein (Anbieter: Click & Boat)</li>
        </ul>
        <p>
          Die Gewinne sind nicht übertragbar, und eine Barauszahlung ist ausgeschlossen. Alle
          Gewinne müssen innerhalb von sechs Wochen nach Benachrichtigung eingefordert werden.
        </p>

        <h5>4. Gewinnbenachrichtigung</h5>
        <p>
          Gewinne werden direkt nach dem Drehen am Glücksrad angezeigt. Zusätzlich erhalten die
          Teilnehmer eine Benachrichtigung per E-Mail. Um den Gewinn zu beanspruchen, müssen die
          Gewinner ihre Adresse für den Versand angeben, sofern dies für die Gewinnabwicklung
          erforderlich ist. Der Versand der Gewinne erfolgt ausschließlich innerhalb Deutschlands.
        </p>

        <h5>5. Ausschluss von Teilnehmenden</h5>
        <p>
          Die Bootsschule1 behält sich das Recht vor, Teilnehmer bei einem Verstoß gegen die
          Teilnahmebedingungen oder bei dem Versuch technischer Manipulationen von der Teilnahme
          auszuschließen. Gewinne können in solchen Fällen auch nachträglich aberkannt oder
          zurückgefordert werden.
        </p>

        <h5>6. Haftungsausschluss</h5>
        <p>
          Die Bootsschule1 haftet nicht für technische oder sonstige Probleme, die außerhalb ihres
          Einflussbereichs liegen, etwa Ausfälle des Internets, Computerviren oder unberechtigte
          Eingriffe durch Dritte.
        </p>

        <h5>7. Haftungsausschluss für Bootsschule1</h5>
        <p>
          Technische oder sonstige Probleme, die außerhalb des Einflussbereichs von Bootsschule1
          stehen, unterliegen nicht der Haftung durch den Betreiber. Etwaige
          Gewährleistungsansprüche der Gewinner sind ausgeschlossen.
        </p>

        <h5>8. Änderung/Einstellung des Gewinnspiels</h5>
        <p>
          Irrtümer und Druckfehler vorbehalten. Bootsschule1 behält sich das Recht vor, das
          Gewinnspiel im Fall unvorhergesehener Umstände zu ändern oder einzustellen. Bootsschule1
          ist insbesondere berechtigt, das Gewinnspiel einzustellen, abzubrechen oder auszusetzen,
          wenn:
        </p>
        <ul>
          <li>ein versuchter Missbrauch durch Manipulation festgestellt wird oder</li>
          <li>
            eine ordnungsgemäße Durchführung nicht mehr sichergestellt ist, dies insbesondere beim
            Ausfall von Hard- oder Software, bei Programmfehlern, Computerviren oder bei nicht
            autorisierten Eingriffen von Dritten sowie mechanischen, technischen oder rechtlichen
            Problemen.
          </li>
        </ul>

        <h5>9. Unwirksamkeit einzelner Klauseln</h5>
        <p>
          Sollten einzelne Bestimmungen dieser Teilnahmebedingungen unwirksam sein oder werden,
          bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon unberührt. An ihre Stelle
          treten die gesetzlichen Bestimmungen.
        </p>

        <h5>10. Übertragung, Barauszahlung, Rechtsweg</h5>
        <p>
          Eine Barauszahlung findet nicht statt. Die Gewinne werden den Gewinner nur innerhalb
          Deutschlands übergeben bzw. zugestellt. Eventuelle Gewinnansprüche sind nicht übertragbar.
          Ein Umtausch ist ausgeschlossen. Der Rechtsweg ist ausgeschlossen.
        </p>
        <p>
          Ausschließlich anwendbares Recht ist das Recht der Bundesrepublik Deutschland. Die
          Rechtswahl gilt jedoch nicht insoweit, als der teilnehmenden Person der Schutz entzogen
          wird, der ihr durch diejenigen Bestimmungen gewährt wird, von denen nach dem Recht des
          Staates, in der die teilnehmende Person ihren gewöhnlichen Aufenthalt hat, nicht durch
          Vereinbarung abgewichen werden darf.
        </p>

        <h5>11. Datenschutz</h5>
        <p>
          Die von Ihnen angegebenen personenbezogenen Daten (Name, E-Mail-Adresse und ggf.
          Anschrift) werden ausschließlich für die Durchführung des Gewinnspiels und die
          Gewinnbenachrichtigung verwendet. Ihre Daten werden vertraulich behandelt und nicht an
          Dritte weitergegeben, es sei denn, dies ist für die Abwicklung des Gewinnspiels
          erforderlich. Rechtsgrundlage ist Art. 6 Abs. 1 lit. b) DSGVO.
        </p>
        <p>
          Weitere Informationen zum Datenschutz finden Sie in unserer Datenschutzerklärung auf
          unserer Webseite.
        </p>

        <h5>12. Änderung oder Einstellung des Gewinnspiels</h5>
        <p>
          Die Bootsschule1 behält sich das Recht vor, das Gewinnspiel bei unvorhergesehenen
          Umständen zu ändern oder abzubrechen. Dies gilt insbesondere bei Missbrauch, technischen
          Problemen oder rechtlichen Hindernissen.
        </p>
        <h5>13. Schlussbestimmungen</h5>
        <p>
          Der Rechtsweg ist ausgeschlossen. Es gilt ausschließlich das Recht der Bundesrepublik
          Deutschland.
        </p>
      </B1Modal>
    </Layout>
  );
};

export default Gluecksrad;
