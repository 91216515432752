import { DateTime } from 'luxon';
import { AccountStatus } from '../api/interfaces/account-info';
import { CourseType } from '../api/interfaces/course';
import UserCourse from '../api/interfaces/user-course';

export const getCourseName = (courseType: CourseType | string) => {
  switch (courseType.toUpperCase()) {
    case CourseType.SBFS:
    case CourseType.PSBFS:
      return 'SBF See';
    case CourseType.SBFB:
    case CourseType.PSBFB:
      return 'SBF Binnen';
    case CourseType.SBFBB:
    case CourseType.PSBFBB:
      return 'SBF Binnen Basisfragen';
    case CourseType.SBFN:
    case CourseType.PSBFN:
      return 'SBF Navigation';
    case CourseType.SBFBS:
      return 'SBF Binnen Segel';
    case CourseType.SBFBSP:
      return 'Segelpraxis';
    case CourseType.BSP:
      return 'Bodenseeschifferpatent (BSP)';
    case CourseType.BSPD:
      return 'Bodenseeschifferpatent Kategorie D';
    case CourseType.BSPDSP:
      return 'Segelpraxis';
    case CourseType.SRC:
    case CourseType.PSRC:
      return 'Funkschein (SRC)';
    case CourseType.SRCP:
      return 'Funkpraxis';
    case CourseType.UBI:
    case CourseType.PUBI:
      return 'Funkschein (UBI)';
    case CourseType.UBIP:
      return 'Funkpraxis';
    case CourseType.SKS:
      return 'Sportküstenschifferschein (SKS)';
    case CourseType.FKN:
      return 'Fachkundenachweis (FKN)';
    case CourseType.SKN:
      return 'Sachkundenachweis (SKN)';
    default:
      return '';
  }
};

export const getShortCourseName = (courseType?: CourseType | string) => {
  switch (courseType?.toUpperCase()) {
    case CourseType.SBFS:
    case CourseType.PSBFS:
      return 'SBF See';
    case CourseType.SBFB:
    case CourseType.PSBFB:
      return 'SBF Binnen';
    case CourseType.SBFBB:
    case CourseType.PSBFBB:
      return 'Binnen Basisfragen';
    case CourseType.SBFN:
    case CourseType.PSBFN:
      return 'Navigation';
    case CourseType.SBFBS:
      return 'Binnen Segel';
    case CourseType.SBFBSP:
      return 'Segelpraxis';
    case CourseType.BSP:
      return 'BSP';
    case CourseType.BSPD:
      return 'BSP Kat. D';
    case CourseType.BSPDSP:
      return 'Segelpraxis';
    case CourseType.SRC:
    case CourseType.PSRC:
      return 'SRC';
    case CourseType.SRCP:
      return 'Funkpraxis';
    case CourseType.UBI:
    case CourseType.PUBI:
      return 'UBI';
    case CourseType.UBIP:
      return 'Funkpraxis';
    case CourseType.SKS:
      return 'SKS';
    case CourseType.FKN:
      return 'FKN';
    case CourseType.SKN:
      return 'SKN';
    default:
      return '';
  }
};

export const getCourseIcon = (courseType?: CourseType) => {
  switch (courseType) {
    case CourseType.SBFS:
    case CourseType.PSBFS:
      return 'assets/icons/boat.svg';
    case CourseType.SBFB:
    case CourseType.PSBFB:
      return 'assets/icons/boat.svg';
    case CourseType.SBFBB:
    case CourseType.PSBFBB:
      return 'assets/icons/boat.svg';
    case CourseType.SBFN:
    case CourseType.PSBFN:
      return 'assets/icons/BS1_Icon_Navigation.svg';
    case CourseType.SBFBS:
      return 'assets/icons/boat.svg';
    case CourseType.SBFBSP:
      return 'assets/icons/boat.svg';
    case CourseType.BSP:
      return 'assets/icons/boat.svg';
    case CourseType.BSPD:
      return 'assets/icons/boat.svg';
    case CourseType.BSPDSP:
      return 'assets/icons/boat.svg';
    case CourseType.SRC:
    case CourseType.PSRC:
      return 'assets/icons/boat.svg';
    case CourseType.UBI:
    case CourseType.PUBI:
      return 'assets/icons/boat.svg';
    case CourseType.SKS:
      return 'assets/icons/boat.svg';
    case CourseType.FKN:
      return 'assets/icons/boat.svg';
    case CourseType.SKN:
      return 'assets/icons/boat.svg';
    default:
      return 'assets/icons/boat.svg';
  }
};

export const getCourseImage = (courseType: CourseType) => {
  switch (courseType) {
    case CourseType.SBFS:
      return 'assets/images/sbfs-background.webp';
    case CourseType.SBFB:
      return 'assets/images/sbfb-background.webp';
    case CourseType.SBFBS:
      return 'assets/images/sbfbs-background.webp';
    case CourseType.BSP:
      return 'assets/images/bsp-background.webp';
    case CourseType.BSPD:
      return 'assets/images/bspd-background.webp';
    case CourseType.SRC:
      return 'assets/images/src-background.webp';
    case CourseType.UBI:
      return 'assets/images/ubi-background.webp';
    case CourseType.SKS:
      return 'assets/images/sks-background.webp';
    case CourseType.FKN:
      return 'assets/images/fkn-background.webp';
    case CourseType.SKN:
      return 'assets/images/skn-background.webp';
    default:
      return '';
  }
};

/**
 * Build user info url parameters
 * @param userState The user state
 * @param leadingChar The leading character, default is "?"
 * @returns The user info url parameters
 */
const buiup = (userState?: AccountStatus, leadingChar: '?' | '&' = '?') => {
  if (!userState) return '';

  const m: { [key: string]: string | undefined } = {
    bfn: userState.firstname,
    bln: userState.lastname,
    bem: userState.email,
    ba1: userState.street + ' ' + userState.house_nbr,
    ba2: '',
    bpo: userState.zip,
    bci: userState.place,
    bcy: userState.country_code,
  };

  return (
    leadingChar +
    Object.keys(m)
      .filter((key) => m[key])
      .map((key) => `${key}=${encodeURIComponent(m[key]!)}`)
      .join('&')
  );
};

export const getCourseSellupPageLink = (courseType: CourseType, userState?: AccountStatus) => {
  switch (courseType) {
    case CourseType.SBFS:
      return 'https://bootsschule1.de/sportbootfuehrerschein-see/' + buiup(userState);
    case CourseType.SBFB:
      return 'https://bootsschule1.de/sportbootfuehrerschein-binnen/' + buiup(userState);
    case CourseType.SBFBS:
      // return 'https://bootsschule1.de/produkt/sbf-binnen-segel-erweiterung/';
      return 'https://bootsschule1.de/checkout/?add-to-cart=151301' + buiup(userState, '&');
    case CourseType.BSP:
      return 'https://bootsschule1.de/bodenseeschifferpatent/' + buiup(userState);
    case CourseType.BSPD:
      // return 'https://bootsschule1.de/produkt/bsp-kat-d/';
      return 'https://bootsschule1.de/checkout/?add-to-cart=151307' + buiup(userState, '&');
    case CourseType.SRC:
      return 'https://bootsschule1.de/src-funkschein/' + buiup(userState);
    case CourseType.UBI:
      return 'https://bootsschule1.de/ubi-funkschein/' + buiup(userState);
    case CourseType.SKS:
      return 'https://bootsschule1.de/sportkuestenschifferschein-sks/' + buiup(userState);
    case CourseType.FKN:
      return 'https://bootsschule1.de/fachkundenachweis-fkn/' + buiup(userState);
    case CourseType.SKN:
      return 'https://bootsschule1.de/sachkundenachweis-skn/' + buiup(userState);
    default:
      return '';
  }
};

export const getCourseUpgradeShopLink = (courseTypes: CourseType[], userState?: AccountStatus) => {
  if (courseTypes.includes(CourseType.SBFS) && courseTypes.includes(CourseType.SBFB)) {
    // return 'https://bootsschule1.de/produkt/sbf-see-binnen-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=20481' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.SBFS)) {
    // return 'https://bootsschule1.de/produkt/sbf-see-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=20479' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.SBFB)) {
    // return 'https://bootsschule1.de/produkt/sbf-binnen-upgrade';
    return 'https://bootsschule1.de/checkout/?add-to-cart=20482' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.SBFBS)) {
    // return 'https://bootsschule1.de/produkt/sbf-binnen-segel-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=151308' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.BSP)) {
    // return 'https://bootsschule1.de/produkt/bodenseeschifferpatent-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=20483' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.BSPD)) {
    // return 'https://bootsschule1.de/produkt/bsp-kat-d-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=151309' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.SRC)) {
    // return 'https://bootsschule1.de/produkt/src-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=61857' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.UBI)) {
    // return 'https://bootsschule1.de/produkt/ubi-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=61858' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.SKS)) {
    // return 'https://bootsschule1.de/produkt/sks-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=61859' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.FKN)) {
    // return 'https://bootsschule1.de/produkt/fkn-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=61860' + buiup(userState, '&');
  } else if (courseTypes.includes(CourseType.SKN)) {
    // return 'https://bootsschule1.de/produkt/skn-upgrade/';
    return 'https://bootsschule1.de/checkout/?add-to-cart=61861' + buiup(userState, '&');
  } else {
    return '';
  }
};

export const SBF_COURSE_TYPES = [
  CourseType.SBFS,
  CourseType.SBFB,
  CourseType.SBFN,
  CourseType.SBFBB,
];
export const SBFBS_COURSE_TYPES = [CourseType.SBFBS, CourseType.SP];
export const BSP_COURSE_TYPES = [CourseType.BSP];
export const BSPD_COURSE_TYPES = [CourseType.BSPD, CourseType.SP];
export const SRC_COURSE_TYPES = [CourseType.SRC, CourseType.SRCP];
export const UBI_COURSE_TYPES = [CourseType.UBI, CourseType.UBIP];
export const SKS_COURSE_TYPES = [CourseType.SKS];
export const FKN_COURSE_TYPES = [CourseType.FKN];
export const SKN_COURSE_TYPES = [CourseType.SKN];

// Partner courses
export const PSBF_COURSE_TYPES = [
  CourseType.PSBFS,
  CourseType.PSBFB,
  CourseType.PSBFN,
  CourseType.PSBFBB,
];
export const PSRC_COURSE_TYPES = [CourseType.PSRC];
// PUBIP only used for the supplementary exam, which isn't present on the PSRC course
export const PUBI_COURSE_TYPES = [CourseType.PUBI, CourseType.PUBIP];

export const STANDALONE_COURSE_TYPES: CourseType[] = [];
export const SHOWCASE_COURSE_TYPES = [
  CourseType.SBFS,
  CourseType.SBFB,
  CourseType.SBFBS,
  CourseType.BSP,
  CourseType.BSPD,
  CourseType.SKS,
  CourseType.SRC,
  CourseType.UBI,
  CourseType.FKN,
  CourseType.SKN,
];
export const PARTNER_COURSE_TYPES = [
  CourseType.PSBFS,
  CourseType.PSBFB,
  CourseType.PSRC,
  CourseType.PUBI,
];

export const getCoursesProgress = (userCourses: UserCourse[], courseTypes: CourseType[]) => {
  let courses = userCourses.filter((c) => c.claimed && courseTypes.includes(c.identifier));

  if (courses.find((c) => c.identifier === CourseType.SBFS)) {
    courses = courses.filter((c) => c.identifier !== CourseType.SBFBB);
  }

  return courses.reduce((a, b) => a + b.progress, 0) / courses.length;
};

export const getMainCourse = (identifier: string) => {
  switch (identifier.toUpperCase()) {
    case CourseType.SBFS:
    case CourseType.SBFB:
    case CourseType.SBFBB:
    case CourseType.SBFN:
      return 'sbf';
    case CourseType.SBFBSP:
      return 'sbfbs';
    case CourseType.BSPDSP:
      return 'bspd';
    case CourseType.PSBFS:
    case CourseType.PSBFB:
    case CourseType.PSBFN:
    case CourseType.PSBFBB:
      return 'psbf';
    case CourseType.SRCP:
      return 'src';
    case CourseType.UBIP:
      return 'ubi';
    case CourseType.PUBIP:
      return 'pubi';
    default:
      return identifier.toLowerCase();
  }
};

export const getRelatedCourseTypes = (identifier: string) => {
  switch (identifier.toUpperCase()) {
    case CourseType.SBFS:
    case CourseType.SBFB:
    case CourseType.SBFBB:
    case CourseType.SBFN:
      return SBF_COURSE_TYPES;
    case CourseType.SBFBS:
    case CourseType.SBFBSP:
      return SBFBS_COURSE_TYPES;
    case CourseType.BSP:
      return BSP_COURSE_TYPES;
    case CourseType.BSPD:
    case CourseType.BSPDSP:
      return BSPD_COURSE_TYPES;
    case CourseType.SRC:
    case CourseType.SRCP:
      return SRC_COURSE_TYPES;
    case CourseType.UBI:
    case CourseType.UBIP:
      return UBI_COURSE_TYPES;
    case CourseType.SKS:
      return SKS_COURSE_TYPES;
    case CourseType.FKN:
      return FKN_COURSE_TYPES;
    case CourseType.SKN:
      return SKN_COURSE_TYPES;

    // Partner courses
    case CourseType.PSBFS:
    case CourseType.PSBFB:
    case CourseType.PSBFN:
    case CourseType.PSBFBB:
      return PSBF_COURSE_TYPES;
    case CourseType.PSRC:
      return PSRC_COURSE_TYPES;
    case CourseType.PUBI:
    case CourseType.PUBIP:
      return PUBI_COURSE_TYPES;

    default:
      return [];
  }
};

export const getCourseOrderIndex = (identifier: string) => {
  switch (identifier) {
    case CourseType.SBFS:
      return 0;
    case CourseType.SBFB:
      return 1;
    case CourseType.SBFN:
      return 2;
    case CourseType.SBFBB:
      return 3;
    case CourseType.SBFBS:
      return 4;
    case CourseType.SBFBSP:
      return 5;
    case CourseType.BSP:
      return 6;
    case CourseType.BSPD:
      return 7;
    case CourseType.BSPDSP:
      return 8;
    case CourseType.SRC:
      return 9;
    case CourseType.UBI:
      return 10;
    case CourseType.UBIP:
      return 11;
    case CourseType.SKS:
      return 12;
    case CourseType.FKN:
      return 13;
    case CourseType.SKN:
      return 14;

    // Partner courses
    case CourseType.PSBFS:
      return 15;
    case CourseType.PSBFB:
      return 16;
    case CourseType.PSBFN:
      return 17;
    case CourseType.PSBFBB:
      return 18;
    case CourseType.PSRC:
      return 19;
    case CourseType.PUBI:
      return 20;
    case CourseType.PUBIP:
      return 21;

    default:
      return 100;
  }
};

export const getValidUntil = (course: UserCourse) => {
  let icon = '';
  let expirationText = '';

  if (course.valid_until && !course.identifier.toUpperCase().startsWith('P')) {
    const date = DateTime.fromISO(course.valid_until);
    const daysValid = Math.round(date.diffNow('days').days);

    if (daysValid < 0 || course.expired) {
      icon = 'cross-circle.svg';
      expirationText = 'Dein Kurszugang ist abgelaufen.';
    } else if (daysValid === 0) {
      icon = 'warning.svg';
      expirationText = 'Dein Kurszugang läuft heute ab.';
    } else if (daysValid <= 90) {
      icon = 'warning.svg';
      expirationText = `Dein Kurszugang läuft in ${daysValid} Tag${daysValid === 1 ? '' : 'en'} ab.`;
    }
  }

  return { icon, expirationText };
};
