import { useIonRouter } from '@ionic/react';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { checkQuestionAnswer } from '../../api/courses/answer';
import { getChapterQuestions } from '../../api/courses/learn-mode';
import Course, { Chapter } from '../../api/interfaces/course';
import { Question as IQuestion, QuestionType, QuizQuestion } from '../../api/interfaces/quiz';
import B1Button from '../../components/buttons/B1Button';
import Question from '../../components/questions/Question';
import { QUIZ_REDUCER } from '../../reducers/quiz-reducer';
import { isApiError } from '../../utils/api-util';
import { wait } from '../../utils/misc-util';
import { isAnswerCorrect, isAnswerPartiallyCorrect } from '../../utils/quiz-util';
import Learnmode from './Learnmode';
import NextButton from './components/NextButton';

import './ChapterQuiz.scss';

const ChapterQuiz: React.FC = () => {
  const { identifier, chapterId: chapterIdParam } = useParams<{
    identifier: string;
    chapterId: string;
  }>();

  const [course, setCourse] = useState<Course>();
  // const [lessonId, setLessonId] = useState<number>(0);
  const [chapter, setChapter] = useState<Chapter>();
  const [mode, setMode] = useState<'quiz' | 'summary'>('quiz');
  const [quiz, dispatchQuiz] = useReducer(QUIZ_REDUCER<IQuestion, QuizQuestion>, {
    loaded: false,
    questions: [],
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>();
  const [showAnswersSummary, setShowAnswersSummary] = useState<
    'correct' | 'partially-correct' | 'wrong'
  >();

  const chapterQuizContainerRef = useRef<HTMLDivElement>(null);

  const ionRouter = useIonRouter();

  useEffect(() => {
    if (course && chapterIdParam) {
      dispatchQuiz({ type: 'SET_QUESTIONS', questions: [] });
      setMode('quiz');
      setCurrentQuestionIndex(0);

      const chapterId = parseInt(chapterIdParam);

      if (isNaN(chapterId)) {
        ionRouter.push('/course/' + course.identifier, 'back', 'replace');
        return;
      }

      getChapterQuestions(course.identifier, chapterId).then((res) => {
        if (isApiError(res)) {
          console.error(res);
        } else {
          dispatchQuiz({
            type: 'SET_QUESTIONS',
            questions: res,
          });
        }
      });

      for (let i = 0; i < course.lessons.length; i++) {
        const lesson = course.lessons[i];
        const chapter = lesson.chapters.find((c) => c.id === chapterId);

        if (chapter) {
          // setLessonId(i);
          setChapter(chapter);
        }
      }
    }
  }, [course, chapterIdParam]);

  useEffect(() => {
    const nextQuestion = getNextQuestion();

    if (nextQuestion && currentQuestionIndex === undefined) {
      setCurrentQuestionIndex(nextQuestion.index);
    }
  }, [currentQuestionIndex]);

  const getChapterQuestionCircleClass = (index: number) => {
    const classNames = ['chapter-question-circle'];

    if (index === currentQuestionIndex) {
      classNames.push('chapter-question-active');
    } else if (index !== currentQuestionIndex) {
      classNames.push('chapter-question-inactive');
    }

    const question = quiz.questions[index];

    if (question.type === QuestionType.TF) {
      if (question.textQuestionAnswer) {
        if (question.textQuestionAnswer.user_points === question.textQuestionAnswer.total_points) {
          classNames.push('chapter-question-correct');
        } else if (question.textQuestionAnswer.user_points === 0) {
          classNames.push('chapter-question-wrong');
        } else {
          classNames.push('chapter-question-partially-correct');
        }
      }
    } else {
      if (
        question.checkedAnswers !== undefined &&
        (currentQuestionIndex !== index || question.evaluationStatus === 'evaluated')
      ) {
        if (isAnswerCorrect(question)) {
          classNames.push('chapter-question-correct');
        } else {
          classNames.push('chapter-question-wrong');
        }
      }
    }

    return classNames.join(' ');
  };

  const getNextQuestion = () => {
    const questions = quiz.questions.map((question, index) => {
      return { question: question, index: index };
    });

    if (currentQuestionIndex === undefined) {
      return questions.find((q) => {
        if (q.question.type === QuestionType.TF) {
          return q.question.textAnswerInput === undefined;
        } else {
          return q.question.checkedAnswers === undefined;
        }
      });
    }

    return questions.find((q) => {
      if (q.question.type === QuestionType.TF) {
        return q.index > currentQuestionIndex && q.question.textAnswerInput === undefined;
      } else {
        return q.index > currentQuestionIndex && q.question.checkedAnswers === undefined;
      }
    });
  };

  const goToNextQuestion = () => {
    const nextQuestion = getNextQuestion();

    if (nextQuestion) {
      setCurrentQuestionIndex(nextQuestion.index);
    }
  };

  const evaluateAnswer = async () => {
    if (currentQuestionIndex === undefined) return;

    const question = quiz.questions[currentQuestionIndex];

    if (question.type === QuestionType.TF) {
      if (!course || !question.textAnswerInput) return;

      dispatchQuiz({
        type: 'SET_ANSWER_EVALUATION_STATUS',
        questionIndex: currentQuestionIndex,
        status: 'evaluating',
      });

      const res = await checkQuestionAnswer(
        course.identifier,
        question.id,
        question.textAnswerInput
      );

      if (isApiError(res)) {
        console.error(res);

        dispatchQuiz({
          type: 'SET_ANSWER_EVALUATION_STATUS',
          questionIndex: currentQuestionIndex,
          status: 'not-evaluated',
        });
      } else {
        dispatchQuiz({
          type: 'SET_TEXT_ANSWER_RESPONSE',
          questionIndex: currentQuestionIndex,
          userPoints: res.user_points,
          totalPoints: res.total_points,
          responseText: res.answer,
          responsePictureLink: res.picture_link,
        });
      }
    } else {
      dispatchQuiz({
        type: 'SET_ANSWER_EVALUATION_STATUS',
        questionIndex: currentQuestionIndex,
        status: 'evaluated',
      });
    }
  };

  const showQuestionHint =
    currentQuestionIndex !== undefined &&
    quiz.questions[currentQuestionIndex] &&
    quiz.questions[currentQuestionIndex].evaluationStatus === 'evaluated' &&
    !!quiz.questions[currentQuestionIndex].hint;

  const correctAnswers = quiz.questions.filter(isAnswerCorrect).length;
  const partiallyCorrectAnswers = quiz.questions.filter(isAnswerPartiallyCorrect).length;

  const showAnswers = (answers: 'correct' | 'partially-correct' | 'wrong') => {
    if (showAnswersSummary === undefined || showAnswersSummary !== answers) {
      setShowAnswersSummary(answers);
    } else {
      setShowAnswersSummary(undefined);
    }
  };

  const shouldShowAnswer = (question: QuizQuestion) => {
    const correct = isAnswerCorrect(question);
    const partiallyCorrect = isAnswerPartiallyCorrect(question);

    if (showAnswersSummary === 'correct') {
      return correct;
    } else if (showAnswersSummary === 'wrong') {
      return !correct && !partiallyCorrect;
    } else if (showAnswersSummary === 'partially-correct') {
      return partiallyCorrect;
    } else {
      return true;
    }
  };

  const scrollToTop = () => {
    wait(100).then(() => {
      if (chapterQuizContainerRef.current) {
        chapterQuizContainerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    });
  };

  return (
    <Learnmode onCourseLoaded={setCourse}>
      <div className='chapter-quiz' ref={chapterQuizContainerRef}>
        <h1>Fragen: {chapter?.title}</h1>
        {mode === 'quiz' && (
          <>
            <div className='chapter-questions-circles'>
              {quiz.questions.map((_question, index) => (
                <div
                  key={index}
                  className={getChapterQuestionCircleClass(index)}
                  // onClick={() => goToQuestion(index)}
                >
                  {index + 1}
                </div>
              ))}
            </div>
            {currentQuestionIndex !== undefined && quiz.questions[currentQuestionIndex] && (
              <Question
                courseIdentifier={identifier}
                question={quiz.questions[currentQuestionIndex]}
                showQuestionHint={showQuestionHint}
                hasNextQuestion={getNextQuestion() !== undefined}
                onAnswerSelected={(answerId) => {
                  dispatchQuiz({
                    type: 'SET_CHECKED_ANSWER',
                    questionIndex: currentQuestionIndex,
                    checkedAnswer: answerId,
                  });
                }}
                onAnswerTextChanged={(text) => {
                  dispatchQuiz({
                    type: 'SET_TEXT_ANSWER',
                    questionIndex: currentQuestionIndex,
                    textAnswer: text,
                  });
                }}
                onGoToNextQuestionClick={goToNextQuestion}
                onGoToSummaryClick={() => {
                  setMode('summary');
                  setShowAnswersSummary(undefined);
                  scrollToTop();
                }}
                onEvaluateAnswerClick={evaluateAnswer}
                savedQuestionType='learn'
                onSaved={(saved) =>
                  dispatchQuiz({ type: 'SET_SAVED', questionIndex: currentQuestionIndex, saved })
                }
              />
            )}
          </>
        )}
        {mode === 'summary' && (
          <>
            <div className='chapter-quiz-summary'>
              <h5 className='bold'>Dein Ergebnis</h5>
              <p className='summary-text'>
                {correctAnswers < quiz.questions.length / 2
                  ? 'Du hast weniger als 50% der Fragen richtig beantwortet.'
                  : 'Das sieht gut aus! Viel Spaß beim nächsten Kapitel 😊'}
              </p>
              <div className='answers-summary'>
                <B1Button
                  className='correct-answers-summary'
                  click={() => showAnswers('correct')}
                  disabled={correctAnswers === 0 || correctAnswers === quiz.questions.length}
                >
                  <span>{correctAnswers}</span> Richtig
                </B1Button>
                {partiallyCorrectAnswers > 0 && (
                  <B1Button
                    className='partially-correct-answers-summary'
                    click={() => showAnswers('partially-correct')}
                    disabled={
                      partiallyCorrectAnswers === 0 ||
                      partiallyCorrectAnswers === quiz.questions.length
                    }
                  >
                    <span>{partiallyCorrectAnswers}</span> Teilw. richtig
                  </B1Button>
                )}
                <B1Button
                  className='wrong-answers-summary'
                  click={() => showAnswers('wrong')}
                  disabled={
                    quiz.questions.length - correctAnswers - partiallyCorrectAnswers === 0 ||
                    quiz.questions.length - correctAnswers - partiallyCorrectAnswers ===
                      quiz.questions.length
                  }
                >
                  <span>{quiz.questions.length - correctAnswers - partiallyCorrectAnswers}</span>{' '}
                  Falsch
                </B1Button>
              </div>
            </div>
            {quiz.questions.map(
              (question, index) =>
                shouldShowAnswer(question) && (
                  <Question
                    key={index}
                    courseIdentifier={identifier}
                    question={question}
                    questionIndex={index}
                    showAnswer
                    savedQuestionType='learn'
                    onSaved={(saved) =>
                      dispatchQuiz({ type: 'SET_SAVED', questionIndex: index, saved })
                    }
                  />
                )
            )}
            <div className='chapter-quiz-summary-buttons'>
              <B1Button
                className='button-reverse'
                click={() => {
                  setCurrentQuestionIndex(undefined);
                  dispatchQuiz({ type: 'RESET_ANSWERS' });
                  dispatchQuiz({ type: 'SHUFFLE_ANSWERS' });
                  setMode('quiz');
                  scrollToTop();
                }}
              >
                Test wiederholen
              </B1Button>
              {quiz.questions.length - correctAnswers > 0 && (
                <B1Button
                  className='button-reverse'
                  click={() => {
                    setCurrentQuestionIndex(undefined);
                    dispatchQuiz({ type: 'RESET_WRONG_ANSWERS' });
                    dispatchQuiz({ type: 'SHUFFLE_ANSWERS' });
                    setMode('quiz');
                  }}
                >
                  Falsche Fragen wiederholen
                </B1Button>
              )}
              <NextButton
                identifier={identifier}
                course={course}
                chapter={chapter}
                chapterQuestions={quiz.questions.length}
                isQuiz
              />
            </div>
          </>
        )}
      </div>
    </Learnmode>
  );
};

export default ChapterQuiz;
