import { Device, DeviceInfo } from '@capacitor/device';
import { useIonViewWillLeave } from '@ionic/react';
import Hls from 'hls.js';
import { Duration } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import {
  BiExitFullscreen,
  BiFullscreen,
  BiPause,
  BiPlay,
  BiRotateLeft,
  BiRotateRight,
  BiVolumeFull,
  BiVolumeLow,
  BiVolumeMute,
} from 'react-icons/bi';
import {
  MdOutlineAirplay,
  MdOutlineSettings,
  MdOutlineSubtitles,
  MdOutlineSubtitlesOff,
  MdSpeed,
} from 'react-icons/md';
import { RiEqualizerFill } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import { TrackProps } from 'react-player/file';
import screenfull from 'screenfull';

import Video, { VideoQuality } from '../../api/interfaces/video';
import useWindowSize from '../../hooks/window-size-hook';
import { getStorage } from '../../utils/storage';

import './VideoPlayer.scss';

interface VideoPlayerProps {
  video?: Video;
  url?: string;
  play?: boolean;
  onPlayingStateChange?: (playing: boolean) => void;
  className?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  video,
  url,
  play,
  onPlayingStateChange,
  className,
}) => {
  const playerWrapper = useRef<HTMLDivElement>(null);
  const player = useRef<ReactPlayer>(null);
  const [videoElement, setVideoElement] = useState<HTMLVideoElement>();
  const playerVolumeSliderInput = useRef<HTMLInputElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const [playing, setPlaying] = useState(play ?? false);
  const [fullscreen, setFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressTime, setProgressTime] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const [savedProgress, setSavedProgress] = useState(0);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [seeking, setSeeking] = useState(false);
  const [loaded, setLoaded] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [subtitlesEnabled, setSubtitlesEnabled] = useState<boolean>();
  const [controlsVisible, setControlsVisible] = useState<boolean>(!video?.thumbnail_link);
  const [hasVolumeControl, setHasVolumeControl] = useState<boolean>(true);
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const [selectedQuality, setSelectedQuality] = useState(VideoQuality.AUTO);
  const [airPlayButtonVisible, setAirPlayButtonVisible] = useState<boolean>(false);
  const { width } = useWindowSize();

  const [playerKey, setPlayerKey] = useState(0);

  useEffect(() => {
    setPlayerKey(playerKey + 1);
    startPlaying(false);
    setProgress(0);
    setProgressTime(0);
    setSeeking(false);
    setLoaded(0);
    setPlaybackRate(1);
    setSubtitlesEnabled(undefined);
  }, [video]);

  useEffect(() => {
    startPlaying(play ?? false);
  }, [play]);

  useEffect(() => {
    getStorage()
      .get('volume')
      .then((value) => {
        if (value !== null) {
          setVolume(value);
        }
      });

    Device.getInfo().then((deviceInfo) => {
      setDeviceInfo(deviceInfo);

      if (deviceInfo.model.includes('iPad') || deviceInfo.model.includes('iPhone')) {
        setHasVolumeControl(false);
      } else if (
        deviceInfo.model.includes('Mac') &&
        window.matchMedia('(pointer: coarse)').matches
      ) {
        setHasVolumeControl(false);
      } else if (deviceInfo.platform === 'android' || deviceInfo.operatingSystem === 'android') {
        setSelectedQuality(VideoQuality.HD);
      }
    });
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const showControls = () => {
      playerWrapper.current?.classList.add('player-show-controls');

      clearTimeout(timeout);

      let controlsTimeout = 500;

      if (width < 576 || window.matchMedia('(pointer: coarse)').matches) {
        controlsTimeout = 1500;
      }

      timeout = setTimeout(() => {
        playerWrapper.current?.classList.remove('player-show-controls');
      }, controlsTimeout);
    };

    playerWrapper.current?.addEventListener('mousemove', showControls);

    return () => {
      playerWrapper.current?.removeEventListener('mousemove', showControls);
    };
  }, []);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        setFullscreen(screenfull.isFullscreen);
      });
    }
  }, []);

  useIonViewWillLeave(() => {
    startPlaying(false);
  });

  const startPlaying = (playing: boolean) => {
    setPlaying(playing);
    onPlayingStateChange?.(playing);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.WebKitPlaybackTargetAvailabilityEvent && videoElement) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const airPlayAvailabilityChangedHandler = (event: any) => {
        switch (event.availability) {
          case 'available':
            setAirPlayButtonVisible(true);
            break;
          case 'not-available':
            setAirPlayButtonVisible(false);
            break;
        }
      };

      videoElement.addEventListener(
        'webkitplaybacktargetavailabilitychanged',
        airPlayAvailabilityChangedHandler
      );

      // TODO: Noch implementieren?
      // video.addEventListener('webkitcurrentplaybacktargetiswirelesschanged', (event) => {
      //   // updateAirPlayButtonWirelessStyle();
      //   // updatePageDimmerForWirelessPlayback();
      // });

      return () => {
        videoElement.removeEventListener(
          'webkitplaybacktargetavailabilitychanged',
          airPlayAvailabilityChangedHandler
        );
      };
    }
  }, [videoElement]);

  const handlePlayerReady = () => {
    setSubtitlesEnabled(undefined);
    const video = player.current?.getInternalPlayer();

    if (video && video instanceof HTMLVideoElement) {
      setVideoElement(video);

      if (video.textTracks.length > 0) {
        setSubtitlesEnabled(false);

        for (const track of video.textTracks) {
          track.mode = 'hidden';
        }
      }
    }

    const hlsPlayer = player.current?.getInternalPlayer('hls');

    if (hlsPlayer && hlsPlayer instanceof Hls) {
      // console.log('HLS:', hlsPlayer);
      // console.log('Levels:', hlsPlayer.levels);

      if (playerWrapper.current && hlsPlayer.levels.length > 0) {
        // const playerWidth = playerWrapper.current.offsetWidth;
        const playerWidth = width;

        // find the level with the closest width to the player width
        const level = hlsPlayer.levels.reduce((prev, curr) =>
          Math.abs(curr.width - playerWidth) < Math.abs(prev.width - playerWidth) ? curr : prev
        );
        const id = hlsPlayer.levels.findIndex((l) => l === level);
        // const startLevel = Math.min(id + 1, hlsPlayer.levels.length - 1);
        const startLevel = id;

        // console.log(
        //   'Level:',
        //   id,
        //   'levelWidth:',
        //   level.width,
        //   'playerWidth:',
        //   playerWidth,
        //   'startLevel:',
        //   startLevel
        // );

        if (id !== -1) {
          hlsPlayer.startLevel = startLevel;
        }
      }

      // hlsPlayer.on(Hls.Events.LEVEL_LOADING, (event, data) => {
      //   console.log('LEVEL_LOADING:', event, data);
      // });

      // hlsPlayer.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
      //   console.log('LEVEL_SWITCHED:', event, data);
      // });

      // hlsPlayer.on(Hls.Events.LEVEL_SWITCHING, (event, data) => {
      //   console.log('LEVEL_SWITCHING:', event, data);
      // });

      // hlsPlayer.on(Hls.Events.LEVELS_UPDATED, (event, data) => {
      //   console.log('LEVELS_UPDATED:', event, data);
      // });

      // hlsPlayer.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
      //   console.log('MANIFEST_PARSED:', event, data);
      // });

      // hlsPlayer.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
      //   console.log('MANIFEST_LOADED:', event, data);
      // });

      // hlsPlayer.on(Hls.Events.MANIFEST_LOADING, (event, data) => {
      //   console.log('MANIFEST_LOADING:', event, data);
      // });

      // hlsPlayer.on(Hls.Events.MAX_AUTO_LEVEL_UPDATED, (event, data) => {
      //   console.log('MAX_AUTO_LEVEL_UPDATED:', event, data);
      // });
    }

    // TODO: Check if bug with background playback can be fixed.
    // if (window.innerWidth < 576) {
    //   startPlaying(true);
    // }
  };

  const handleFullscreen = () => {
    if (playerWrapper.current && screenfull.isEnabled) {
      screenfull.toggle(playerWrapper.current);
      setFullscreen(screenfull.isFullscreen);
    } else if (!screenfull.isEnabled) {
      if (deviceInfo?.model.includes('iPhone') || deviceInfo?.model.includes('iPad')) {
        const video = player.current?.getInternalPlayer();

        if (video instanceof HTMLVideoElement) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          video.webkitEnterFullscreen();
        }
      }
    }
  };

  const handleProgress = (state: OnProgressProps) => {
    if (!seeking) {
      setProgress(state.played);
      setProgressTime(state.playedSeconds);
      setLoaded(state.loaded);

      if (state.played > 0) {
        setSavedProgress(state.played);
      }
    }
  };

  const handleBufferEnd = () => {
    if (hasStarted) {
      setHasStarted(false);
      player.current?.seekTo(savedProgress);
    }
  };

  const handleQuickSeek = (value: number) => {
    player.current?.seekTo(player.current.getCurrentTime() + value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    switch (event.key) {
      case ' ':
        startPlaying(!playing);
        break;
      case 'ArrowLeft':
        handleQuickSeek(-10);
        break;
      case 'ArrowRight':
        handleQuickSeek(10);
        break;
      case 'ArrowUp':
        handleVolumeChange(Math.min(volume + 0.1, 1));
        break;
      case 'ArrowDown':
        handleVolumeChange(Math.max(volume - 0.1, 0));
        break;
      case 'f':
        handleFullscreen();
        break;
      default:
        break;
    }
  };

  const handleSeekTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleSeekStart(e.touches[0].clientX);
  };

  const handleSeekMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleSeekStart(e.clientX);
  };

  const handleSeekStart = (clientX: number) => {
    if (!progressBarRef.current || !player.current) return;

    setSeeking(true);

    const mousePosition = Math.max(
      clientX - progressBarRef.current.getBoundingClientRect().left,
      0
    );
    const progressBarLength = progressBarRef.current.offsetWidth;
    const progress = mousePosition / progressBarLength;

    setProgress(progress);
    setProgressTime(progress * player.current.getDuration());
    player.current.seekTo(progress);
  };

  const handleSeekTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    handleSeekMove(e.touches[0].clientX);
  };

  const handleSeekMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    // e.preventDefault(); // aktiviert verhindert es das Ändern der Lautstärke mit dem Slider
    // e.stopPropagation();

    if (e.buttons !== 1) return;

    handleSeekMove(e.clientX);
  };

  const handleSeekMove = (clientX: number) => {
    if (!seeking || !progressBarRef.current) return;

    const mousePosition = Math.max(
      clientX - progressBarRef.current.getBoundingClientRect().left,
      0
    );
    const progressBarLength = progressBarRef.current.offsetWidth;
    const progress = mousePosition / progressBarLength;

    setProgress(progress);
  };

  useEffect(() => {
    const handleSeekMouseUp = () => {
      if (seeking && player.current) {
        setSeeking(false);
        setProgressTime(progress * player.current.getDuration());
        player.current.seekTo(progress);
      }
    };

    window.addEventListener('mouseup', handleSeekMouseUp);
    window.addEventListener('touchend', handleSeekMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleSeekMouseUp);
      window.removeEventListener('touchend', handleSeekMouseUp);
    };
  }, [seeking, progress]);

  const handleVolumeChange = (value: number) => {
    setVolume(value);
    setMuted(value === 0);

    getStorage().set('volume', value);
  };

  const toggleSubtitles = () => {
    const video = player.current?.getInternalPlayer();

    if (video && video instanceof HTMLVideoElement) {
      if (video.textTracks.length > 0) {
        video.textTracks[0].mode = subtitlesEnabled ? 'hidden' : 'showing';

        setSubtitlesEnabled(!subtitlesEnabled);
      }
    }
  };

  const formatProgressTime = () => {
    let time = progressTime;

    if (seeking) {
      let duration = player.current?.getDuration() ?? 0;

      if (isNaN(duration)) {
        duration = 0;
      }

      time = progress * duration;
    }

    return Duration.fromMillis(time * 1000).toFormat('mm:ss');
  };

  const formatTotalTime = () => {
    let duration = player.current?.getDuration() ?? 0;

    if (isNaN(duration)) {
      duration = 0;
    }

    return Duration.fromMillis(duration * 1000).toFormat('mm:ss');
  };

  const getSubtitles: () => TrackProps[] = () => {
    if (!video?.vtt_link) return [];

    return [
      {
        kind: 'subtitles',
        label: 'Deutsch',
        srcLang: 'de',
        default: true,
        src: video.vtt_link,
      },
    ];
  };

  const getVideoUrl = () => {
    let videoUrl: string | undefined;

    switch (selectedQuality) {
      case VideoQuality.AUTO:
        videoUrl = video?.hls_link;
        break;
      case VideoQuality.HD:
        videoUrl = video?.mp4_720p_link;
        break;
      case VideoQuality.FullHD:
        videoUrl = video?.mp4_1080p_link;
        break;
      case VideoQuality.UHD:
        videoUrl = video?.mp4_2160p_link;
        break;
      default:
        videoUrl = video?.mp4_720p_link;
        break;
    }

    return videoUrl ?? url;
  };

  const isYouTubeVideo = () => {
    const url = getVideoUrl();

    if (url?.startsWith('https://youtube.com') || url?.startsWith('https://youtu.be')) {
      return true;
    }

    return false;
  };

  const shouldPlayInline = () => {
    if (deviceInfo?.iOSVersion !== undefined) {
      if (deviceInfo.iOSVersion < 170000) {
        return false;
      }
    }

    return true;
  };

  const openAirPlay = () => {
    const video = player.current?.getInternalPlayer();

    if (video && video instanceof HTMLVideoElement) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      video.webkitShowPlaybackTargetPicker();
    }
  };

  return (
    <div
      ref={playerWrapper}
      className={`player-wrapper${className ? ' ' + className : ''}`}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      style={{
        backgroundImage: video?.thumbnail_link ? `url(${video?.thumbnail_link})` : undefined,
      }}
      onMouseMove={handleSeekMouseMove}
      onTouchMove={handleSeekTouchMove}
    >
      <div className='player' onClick={() => startPlaying(!playing)}>
        <ReactPlayer
          className='react-player'
          ref={player}
          url={getVideoUrl()}
          light={video?.thumbnail_link ?? false}
          playIcon={
            <div className='play-icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='95'
                height='92'
                viewBox='0 0 95 92'
                fill='none'
              >
                <rect width='94.7463' height='92' rx='46' fill='white' />
                <path
                  d='M63.5493 44.4463C64.2236 44.8297 64.2236 45.8015 63.5493 46.1849L39.3844 59.9251C38.7177 60.3042 37.8901 59.8227 37.8901 59.0558L37.8901 31.5753C37.8901 30.8085 38.7177 30.327 39.3844 30.706L63.5493 44.4463Z'
                  fill='#021DF0'
                />
              </svg>
            </div>
          }
          key={playerKey}
          playing={playing}
          width='100%'
          height='100%'
          muted={muted}
          volume={volume}
          playbackRate={playbackRate}
          onReady={handlePlayerReady}
          onStart={() => setHasStarted(true)}
          onBufferEnd={handleBufferEnd}
          onProgress={handleProgress}
          onPlay={() => startPlaying(true)}
          onPause={() => startPlaying(false)}
          onEnded={() => startPlaying(false)}
          onClickPreview={() => setControlsVisible(true)}
          progressInterval={50}
          playsinline={shouldPlayInline()}
          config={{
            file: {
              attributes: {
                crossOrigin: 'use-credentials',
              },
              tracks: getSubtitles(),
              hlsOptions: {
                // https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning
                xhrSetup: (xhr: XMLHttpRequest) => {
                  xhr.withCredentials = true;
                },
                // testBandwidth: true,
                // startLevel: -1, // automatic start level selection, playback will start from level matching download bandwidth (determined from download of first segment).
                capLevelToPlayerSize: true, // cap level to the size of the player
              },
            },
            youtube: {
              playerVars: { playsinline: 1, controls: 1 },
            },
          }}
        />
      </div>
      {controlsVisible && !isYouTubeVideo() && (
        <div className='player-overlay'>
          <div
            ref={progressBarRef}
            className='player-progress'
            tabIndex={0}
            onMouseDown={handleSeekMouseDown}
            onTouchStart={handleSeekTouchStart}
          >
            <div className='player-progress-played' style={{ width: progress * 100 + '%' }}></div>
            <div
              className='player-progress-loaded'
              style={{ width: (loaded - progress) * 100 + '%' }}
            ></div>
          </div>
          <div className='player-controls'>
            <div className='player-controls-start'>
              {/* <button type='button'>
                <BiSkipPrevious />
              </button> */}
              <button type='button' onClick={() => startPlaying(!playing)}>
                {playing ? <BiPause /> : <BiPlay />}
              </button>
              {/* <button type='button'>
                <BiSkipNext />
              </button> */}
              <button
                type='button'
                className='fast-forward-rewind'
                onClick={() => handleQuickSeek(-10)}
              >
                <BiRotateLeft />
              </button>
              <button
                type='button'
                className='fast-forward-rewind'
                onClick={() => handleQuickSeek(10)}
              >
                <BiRotateRight />
              </button>
              <div className='player-time'>
                {formatProgressTime()} / {formatTotalTime()}
              </div>
            </div>
            <div className='player-controls-end'>
              {airPlayButtonVisible && (
                <button type='button' className='player-airplay-button' onClick={openAirPlay}>
                  <MdOutlineAirplay />
                </button>
              )}

              <button type='button' className='player-settings-button'>
                <MdOutlineSettings />
              </button>
              <div className='player-settings-selector'>
                <button type='button' className='player-quality-button'>
                  <RiEqualizerFill />
                  <span>Qualität</span>
                </button>
                <div className='player-quality-selector'>
                  {video?.hls_link && (
                    <button
                      type='button'
                      onClick={() => setSelectedQuality(VideoQuality.AUTO)}
                      className={selectedQuality === VideoQuality.AUTO ? 'active' : ''}
                    >
                      Auto
                    </button>
                  )}
                  {video?.mp4_720p_link && (
                    <button
                      type='button'
                      onClick={() => setSelectedQuality(VideoQuality.HD)}
                      className={selectedQuality === VideoQuality.HD ? 'active' : ''}
                    >
                      720p
                    </button>
                  )}
                  {video?.mp4_1080p_link && (
                    <button
                      type='button'
                      onClick={() => setSelectedQuality(VideoQuality.FullHD)}
                      className={selectedQuality === VideoQuality.FullHD ? 'active' : ''}
                    >
                      1080p
                    </button>
                  )}
                  {video?.mp4_2160p_link && (
                    <button
                      type='button'
                      onClick={() => setSelectedQuality(VideoQuality.UHD)}
                      className={selectedQuality === VideoQuality.UHD ? 'active' : ''}
                    >
                      2160p
                    </button>
                  )}
                </div>

                <button
                  type='button'
                  className='player-speed-button'
                  onClick={() => setPlaybackRate(1)}
                >
                  <MdSpeed />
                  <span>Geschwindigkeit</span>
                </button>
                <div className='player-speed-selector'>
                  <button
                    type='button'
                    onClick={() => setPlaybackRate(0.5)}
                    className={playbackRate === 0.5 ? 'active' : ''}
                  >
                    0,5x
                  </button>
                  <button
                    type='button'
                    onClick={() => setPlaybackRate(0.75)}
                    className={playbackRate === 0.75 ? 'active' : ''}
                  >
                    0,75x
                  </button>
                  <button
                    type='button'
                    onClick={() => setPlaybackRate(1)}
                    className={playbackRate === 1 ? 'active' : ''}
                  >
                    1x
                  </button>
                  <button
                    type='button'
                    onClick={() => setPlaybackRate(1.25)}
                    className={playbackRate === 1.25 ? 'active' : ''}
                  >
                    1,25x
                  </button>
                  <button
                    type='button'
                    onClick={() => setPlaybackRate(1.5)}
                    className={playbackRate === 1.5 ? 'active' : ''}
                  >
                    1,5x
                  </button>
                  <button
                    type='button'
                    onClick={() => setPlaybackRate(2)}
                    className={playbackRate === 2 ? 'active' : ''}
                  >
                    2x
                  </button>
                </div>

                {subtitlesEnabled !== undefined && (
                  <button
                    type='button'
                    className='player-subtitles-button'
                    onClick={() => toggleSubtitles()}
                  >
                    {subtitlesEnabled ? <MdOutlineSubtitles /> : <MdOutlineSubtitlesOff />}
                    <span>Untertitel</span>
                  </button>
                )}
              </div>

              <button
                type='button'
                className='player-volume-button'
                onClick={() => setMuted(!muted)}
              >
                {muted || volume === 0 ? (
                  <BiVolumeMute />
                ) : volume >= 0.5 ? (
                  <BiVolumeFull />
                ) : (
                  <BiVolumeLow />
                )}
              </button>
              {hasVolumeControl && (
                <div className='player-volume-slider'>
                  <input
                    ref={playerVolumeSliderInput}
                    type='range'
                    className='custom-slider'
                    min={0}
                    max={1}
                    step='any'
                    value={volume}
                    onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                  />
                </div>
              )}
              <button type='button' onClick={() => handleFullscreen()}>
                {fullscreen ? <BiExitFullscreen /> : <BiFullscreen />}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
