import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRouterLink,
  IonToolbar,
  useIonPopover,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BiBell, BiDotsVerticalRounded, BiLogOut, BiUser } from 'react-icons/bi';
import { BsSnow2 } from 'react-icons/bs';
import { LuSun } from 'react-icons/lu';

import { sendEmailVerification } from '../api/account/email';
import { AccountStatus } from '../api/interfaces/account-info';
import { CourseType } from '../api/interfaces/course';
import B1Button from '../components/buttons/B1Button';
import B1Modal from '../components/modal/B1Modal';
import PushMessagesModal from '../components/modal/push-messages-modal/PushMessagesModal';
import SupportModal from '../components/modal/support-modal/SupportModal';
import { AuthContext } from '../providers/AuthProvider';
import AuthComponent from '../routes/AuthComponent';
import { isApiError } from '../utils/api-util';

import './Layout.scss';

const MoreInfoPopover: React.FC<{ moreInfoContent?: React.ReactNode }> = ({ moreInfoContent }) => (
  <IonContent>
    <IonList>{moreInfoContent}</IonList>
  </IonContent>
);

interface LayoutProps extends PropsWithChildren {
  contentClassName?: string;
  skipAuth?: boolean;
  pageRef?: React.RefObject<HTMLElement>;
  scrollContainerRef?: React.RefObject<HTMLIonContentElement>;
  backButton?: string | boolean | (() => void);
  moreInfoContent?: React.ReactNode;
  additionalHeaderContent?: React.ReactNode;
  footer?: React.ReactNode;
  currentCourseType?: CourseType | string;
  disableTopNavigation?: boolean;
  /**
   * This is to prevent a race condition where the user state is refreshed when the user is on
   * a page that authenticates him (like Login.tsx) and thus updates the isAuthenticated state
   * and at the same time the user is redirected to the home page where the Layout fetches the
   * user state again.
   */
  dontRefreshUserStateWhenLoggedIn?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  contentClassName,
  skipAuth,
  pageRef,
  scrollContainerRef,
  backButton,
  moreInfoContent,
  additionalHeaderContent,
  footer,
  currentCourseType,
  disableTopNavigation,
  dontRefreshUserStateWhenLoggedIn,
}) => {
  const ionRouter = useIonRouter();
  const [presentToast] = useIonToast();
  const [presentMoreInfoPopover, dismissMoreInfoPopover] = useIonPopover(MoreInfoPopover, {
    moreInfoContent,
    onDismiss: (data: unknown, role: string) => dismissMoreInfoPopover(data, role),
  });

  const ionContentRef = React.useRef<HTMLIonContentElement>(null);

  const {
    isAuthenticated,
    refreshed,
    getUserData,
    logout,
    pushMessages,
    snowfallEnabled,
    toggleSnowfall,
  } = useContext(AuthContext);

  const [userState, setUserState] = useState<AccountStatus>();
  const [snowfallActive, setSnowfallActive] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [confirmEmailModalOpen, setConfirmEmailModalOpen] = useState(false);
  const [pushMessagesModalOpen, setPushMessagesModalOpen] = useState(false);

  useEffect(() => {
    if (dontRefreshUserStateWhenLoggedIn && isAuthenticated) return;

    getUserData().then((state) => {
      setUserState(state);

      let active: boolean | undefined;

      if (state?.feature_flags) {
        active = state.feature_flags.find((flag) => flag.name === 'snowfall')?.active;
      }

      setSnowfallActive(active ?? false);
    });
  }, [isAuthenticated, refreshed, getUserData]);

  const logoutButtonHandler = async () => {
    await logout();
    // ionRouter.push('/login', 'root', 'replace', { unmount: true, from: 'logout' } as RouterOptions);
    location.href = '/login';
  };

  const backButtonHandler = () => {
    if (typeof backButton === 'boolean' && backButton) {
      ionRouter.goBack();
    } else if (typeof backButton === 'function') {
      backButton();
    }
  };

  const handleResendVerification = async () => {
    sendEmailVerification().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        presentToast({
          message:
            'Es konnte keine neue Bestätigungsmail gesendet werden.\n' +
            'Bitte versuche es später erneut oder melde Dich bei uns.',
          duration: 5000,
          position: 'bottom',
          color: 'danger',
        });
      } else {
        setConfirmEmailModalOpen(false);
        presentToast({
          message: 'Wir haben Dir eine neue Bestätigungsmail geschickt.',
          duration: 3000,
          position: 'bottom',
          color: 'success',
        });
      }
    });
  };

  useEffect(() => {
    const contentRef = scrollContainerRef ?? ionContentRef;

    if (document.documentElement.classList.contains('plt-android')) return;

    if (contentRef.current?.shadowRoot) {
      contentRef.current.shadowRoot.querySelector('style')?.remove();

      const style = document.createElement('style');

      style.innerHTML = `
        .inner-scroll::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        .inner-scroll::-webkit-scrollbar-track {
          margin-top: 20px;
          background: transparent;
        }

        /* Handle */
        .inner-scroll::-webkit-scrollbar-thumb {
          background: #dedede;
          border-radius: 10px;
        }

        .inner-scroll::-webkit-scrollbar-thumb:hover {
          background: rgb(139, 139, 139);
        }

        .inner-scroll {
          scrollbar-gutter: stable both-edges;

          /* Firefox */
          scrollbar-width: auto;
          scrollbar-color: #dedede transparent;
        }
      `;

      contentRef.current.shadowRoot.prepend(style);
    }
  }, [scrollContainerRef, ionContentRef]);

  return (
    <IonPage ref={pageRef}>
      <IonHeader className='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            {disableTopNavigation ? (
              <div className='logo'>
                <img src='assets/Bootsschule1_Logo_white.webp' />
              </div>
            ) : (
              <IonRouterLink
                routerLink='/'
                routerDirection='back'
                title='Bootsschule1'
                className='logo'
              >
                <img src='assets/Bootsschule1_Logo_white.webp' />
              </IonRouterLink>
            )}
          </IonButtons>
          {!disableTopNavigation && (
            <IonButtons slot='end' className='icon-buttons'>
              {snowfallActive && (
                <IonButton
                  shape='round'
                  onClick={() => toggleSnowfall()}
                  title={`Schneefall ${snowfallEnabled ? 'de' : ''}aktivieren`}
                >
                  {snowfallEnabled ? <LuSun /> : <BsSnow2 />}
                </IonButton>
              )}
              {moreInfoContent && (
                <>
                  <IonButton
                    shape='round'
                    onClick={(e) =>
                      presentMoreInfoPopover({
                        event: e.nativeEvent,
                        cssClass: 'more-info-popover',
                        dismissOnSelect: true,
                        size: 'auto',
                        side: 'bottom',
                        alignment: 'center',
                      })
                    }
                  >
                    <BiDotsVerticalRounded />
                  </IonButton>
                </>
              )}
              {pushMessages.length > 0 && (
                <IonButton
                  className={pushMessages.some((p) => !p.read) ? 'has-unread-messages' : ''}
                  shape='round'
                  onClick={() => setPushMessagesModalOpen(true)}
                >
                  <BiBell />
                </IonButton>
              )}
              <IonButton shape='round' onClick={() => setSupportModalOpen(true)}>
                <AiOutlineQuestionCircle />
              </IonButton>
              {!skipAuth && (
                <AuthComponent showLoadingText={false}>
                  <IonButton shape='round' routerLink='/account'>
                    {userState?.avatar ? (
                      <img src={userState?.avatar} className='avatar' />
                    ) : (
                      <BiUser />
                    )}
                  </IonButton>
                  <IonButton shape='round' onClick={logoutButtonHandler}>
                    <BiLogOut />
                  </IonButton>
                </AuthComponent>
              )}
            </IonButtons>
          )}
        </IonToolbar>
        {backButton || additionalHeaderContent || !userState?.confirmed_email ? (
          <IonToolbar className='additional-header-content'>
            {backButton && (
              <IonButtons slot='start'>
                <IonButton
                  routerLink={typeof backButton === 'string' ? backButton : undefined}
                  routerDirection='back'
                  onClick={backButtonHandler}
                  className='back-button scale-effect'
                >
                  <img src='assets/icons/short-left-arrow.svg' className='d-block d-sm-none' />
                  <img src='assets/icons/long-left-arrow.svg' className='d-none d-sm-block' />
                </IonButton>
              </IonButtons>
            )}
            {additionalHeaderContent}
            {!additionalHeaderContent && userState?.confirmed_email === false && (
              <IonButtons slot='end'>
                <B1Button
                  className='button-sm confirm-email-button'
                  click={() => setConfirmEmailModalOpen(true)}
                >
                  <img src='assets/icons/warning.svg' className='mr-2 warning-image' />
                  Bestätige Deine E-Mail-Adresse
                  <img src='assets/icons/medium-right-arrow.svg' className='ml-2 arrow-image' />
                </B1Button>
              </IonButtons>
            )}
          </IonToolbar>
        ) : (
          <div className='additional-header-empty-slot'></div>
        )}
      </IonHeader>
      <IonContent
        ref={scrollContainerRef ?? ionContentRef}
        className={'main-content ' + (contentClassName ?? '')}
      >
        {children}
      </IonContent>
      {footer}

      <SupportModal
        currentCourseType={currentCourseType}
        open={supportModalOpen}
        onModalClose={() => setSupportModalOpen(false)}
      />

      <B1Modal
        className='confirm-email-modal'
        open={confirmEmailModalOpen}
        onModalClose={() => setConfirmEmailModalOpen(false)}
      >
        <>
          <h1 className='mb-4'>📧</h1>
          <h2 className='mb-4'>E-Mail-Adresse bestätigen</h2>
          <p className='mb-1'>
            Wir haben Dir eine Bestätigungsmail an {userState?.email} geschickt.
          </p>
          <p className='mb-3'>
            Bitte bestätige Deine E-Mail-Adresse, indem Du auf den Link in der E-Mail klickst.
          </p>
          <p className='mb-5'>
            Falls Du keine E-Mail erhalten hast oder der Link abgelaufen ist, kannst Du hier einen
            neuen Link anfordern oder Deine E-Mail-Adresse ändern.
          </p>
          <B1Button
            className='button-block mb-3'
            href='/account/change-email'
            click={() => setConfirmEmailModalOpen(false)}
          >
            E-Mail ändern
          </B1Button>
          <B1Button className='button-block button-reverse' click={handleResendVerification}>
            Neuen Link anfordern
          </B1Button>
        </>
      </B1Modal>

      <PushMessagesModal open={pushMessagesModalOpen} setOpen={setPushMessagesModalOpen} />
    </IonPage>
  );
};

export default Layout;
