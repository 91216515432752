import {
  IonSpinner,
  getConfig,
  useIonRouter,
  useIonToast,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { Duration } from 'luxon';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { BsArrowLeft, BsCheck2Circle } from 'react-icons/bs';
import { useParams } from 'react-router';

import { checkQuestionAnswer } from '../../api/courses/answer';
import {
  getExamPaperQuestions,
  getExamPapers,
  updateExamPaperProgress,
} from '../../api/courses/exam-simulator';
import {
  ExamPaper as ExamPaperInterface,
  ExamPaperQuestion,
  ExamPaperQuestionCategory,
  hasMarginalPassed,
  isExamPaperQuestion,
} from '../../api/interfaces/exam-paper';
import { QuestionType, QuizQuestion } from '../../api/interfaces/quiz';
import B1Button from '../../components/buttons/B1Button';
import B1Modal from '../../components/modal/B1Modal';
import ProgressBar from '../../components/progressbar/ProgressBar';
import Question from '../../components/questions/Question';
import { QUIZ_REDUCER } from '../../reducers/quiz-reducer';
import { isApiError } from '../../utils/api-util';
import { getMainCourse } from '../../utils/course-util';
import { enableSafeArea } from '../../utils/misc-util';
import { isAnswerCorrect } from '../../utils/quiz-util';
import Layout from '../Layout';

import './ExamPaper.scss';

interface QuestionCategory extends ExamPaperQuestionCategory {
  correct: number;
  total: number;
}

const ExamPaper: React.FC = () => {
  const { identifier, examPaperId: examPaperIdParam } = useParams<{
    identifier: string;
    examPaperId: string;
  }>();
  const ionRouter = useIonRouter();
  const [presentToast, dismissToast] = useIonToast();

  const pageRef = useRef<HTMLElement>(null);
  const scrollContainerRef = useRef<HTMLIonContentElement>(null);
  const remainingTimeIntervalRef = useRef<NodeJS.Timeout>();

  const [mode, setMode] = useState<'quiz' | 'summary'>('quiz');
  const [evaluating, setEvaluating] = useState<'evaluating' | 'evaluated' | 'idle'>('idle');
  const [showAnswers, setShowAnswers] = useState(false);
  const [examPaper, setExamPaper] = useState<ExamPaperInterface>();
  const [quiz, dispatchQuiz] = useReducer(QUIZ_REDUCER<ExamPaperQuestion, QuizQuestion>, {
    loaded: false,
    questions: [],
  });
  const [startTime, setStartTime] = useState<Date>();
  const [finishedAt, setFinishedAt] = useState<Date>();
  const [timeNeeded, setTimeNeeded] = useState<string>();
  const [totalTime, setTotalTime] = useState<number>();
  const [remainingTime, setRemainingTime] = useState<string>();
  const [examPaperPassed, setExamPaperPassed] = useState<'passed' | 'marginal-passed' | 'failed'>();
  const [categories, setCategories] = useState<QuestionCategory[]>([]);
  const [confirmSubmitModalOpen, setConfirmSubmitModalOpen] = useState(false);
  const [confirmLeaveModalOpen, setConfirmLeaveModalOpen] = useState(false);

  useIonViewDidEnter(() => {
    getConfig()?.set('swipeBackEnabled', false);
  }, []);

  useIonViewWillLeave(() => {
    getConfig()?.set('swipeBackEnabled', true);
    dismissToast();
  }, []);

  useIonViewWillEnter(() => {
    enableSafeArea('dark');
  });

  useIonViewWillLeave(() => {
    enableSafeArea('light');
  });

  useIonViewDidLeave(() => {
    if (remainingTimeIntervalRef.current !== undefined) {
      clearInterval(remainingTimeIntervalRef.current);
      remainingTimeIntervalRef.current = undefined;
      setStartTime(undefined);
    }
  }, []);

  useBeforeunload((event) => {
    if (mode === 'quiz') {
      event.preventDefault();
      return 'Möchtest Du den Fragebogen wirklich abbrechen?';
    }
  });

  useEffect(() => {
    const examPaperId = parseInt(examPaperIdParam);

    if (isNaN(examPaperId)) {
      ionRouter.push(`/course/${identifier}/exam-training`, 'back', 'pop');
      return;
    }

    getExamPapers(identifier).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      const examPaper = res.exam_papers.find((e) => e.id === examPaperId);

      if (!examPaper) {
        ionRouter.push(`/course/${identifier}/exam-training`, 'back', 'pop');
        return;
      }

      setExamPaper(examPaper);
      setTotalTime(res.exam_paper_processing_time * 60 * 1000);
    });

    getExamPaperQuestions(identifier, examPaperId).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      dispatchQuiz({ type: 'SET_QUESTIONS', questions: res });
      setStartTime(new Date());
    });
  }, [identifier, examPaperIdParam]);

  const handleSubmitExamPaper = async (cause: 'confirm' | 'time-expired' | 'confirmed') => {
    setFinishedAt(new Date());

    if (cause === 'confirm') {
      setConfirmSubmitModalOpen(true);
      return;
    } else if (cause === 'time-expired') {
      // Display for an hour, but user can close it and it will be closed when leaving the page
      presentToast({
        message: 'Die Zeit ist abgelaufen!',
        duration: 60 * 60 * 1000,
        position: 'bottom',
        color: 'danger',
        buttons: [
          {
            role: 'cancel',
            icon: close,
          },
        ],
      });
    } else {
      setConfirmSubmitModalOpen(false);
    }

    setEvaluating('evaluating');

    const questionsEvaluating: Promise<void>[] = [];

    for (let i = 0; i < quiz.questions.length; i++) {
      const question = quiz.questions[i];

      if (question.evaluationStatus === 'not-evaluated') {
        questionsEvaluating.push(evaluateAnswer(question, i));
      }
    }

    await Promise.all(questionsEvaluating);

    setEvaluating('evaluated');
  };

  useEffect(() => {
    if (!startTime || !finishedAt || !examPaper || evaluating !== 'evaluated' || mode === 'summary')
      return;

    const categories: QuestionCategory[] = [];

    for (const question of quiz.questions) {
      let questionUserPoints = 0;

      if (question.type === QuestionType.TF && question.textQuestionAnswer) {
        questionUserPoints = question.textQuestionAnswer.user_points;
      }

      if (isExamPaperQuestion(question)) {
        let identifier = 'uncategorized';
        let correctToPass = Math.round(quiz.questions.length * 0.85);

        if (question.type !== QuestionType.TF && isAnswerCorrect(question)) {
          questionUserPoints = question.points;
        }

        if (question.category) {
          identifier = question.category.identifier;
          correctToPass = question.category.correct_to_pass;
        }

        const category = categories.find((c) => c.identifier === identifier);

        if (category) {
          category.total += question.points;
          category.correct += questionUserPoints;
        } else {
          categories.push({
            identifier: identifier,
            title: question.category?.title ?? 'Fragen',
            correct_to_pass: correctToPass,
            correct: questionUserPoints,
            total: question.points,
          });
        }
      }
    }

    const passed = categories.every((c) => c.correct >= c.correct_to_pass);
    const marginalPassed = categories.some(hasMarginalPassed);

    setExamPaperPassed(passed ? (marginalPassed ? 'marginal-passed' : 'passed') : 'failed');
    setCategories(categories);

    clearInterval(remainingTimeIntervalRef.current);
    remainingTimeIntervalRef.current = undefined;

    setTimeNeeded(
      Duration.fromMillis(finishedAt.getTime() - startTime.getTime()).toFormat('mm:ss')
    );
    setStartTime(undefined);
    setShowAnswers(false);
    setMode('summary');
    setEvaluating('idle');

    updateExamPaperProgress(
      identifier,
      examPaper.id,
      passed,
      categories.map((c) => ({ id: c.identifier, correct: c.correct, total: c.total }))
    ).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }
    });
  }, [evaluating, quiz.questions, startTime, finishedAt, identifier, examPaper]);

  const startRemainingTimeInterval = () => {
    const calculateRemainingTime = () => {
      if (startTime === undefined || totalTime === undefined) return;

      const remainingTimeMillis = totalTime - (Date.now() - startTime.getTime());
      const remainingTime = Math.round(remainingTimeMillis / 1000) * 1000;

      if (remainingTime <= 0) {
        handleSubmitExamPaper('time-expired');
        return;
      }

      setRemainingTime(Duration.fromMillis(remainingTime).toFormat('mm:ss'));
    };

    calculateRemainingTime();

    return setInterval(calculateRemainingTime, 1000);
  };

  useEffect(() => {
    if (startTime === undefined || totalTime === undefined) return;

    if (remainingTimeIntervalRef.current === undefined) {
      remainingTimeIntervalRef.current = startRemainingTimeInterval();
    }
  }, [startTime, totalTime]);

  const getProgress = () => {
    return (
      quiz.questions.filter((q) => {
        if (q.type === QuestionType.TF) {
          return !!q.textAnswerInput;
        } else {
          return q.checkedAnswers !== undefined && q.checkedAnswers.length > 0;
        }
      }).length / quiz.questions.length
    );
  };

  const evaluateAnswer = async (question: QuizQuestion, index: number) => {
    // only evaluate text question answers
    if (question.type === QuestionType.TF) {
      if (!question.textAnswerInput) return;

      dispatchQuiz({
        type: 'SET_ANSWER_EVALUATION_STATUS',
        questionIndex: index,
        status: 'evaluating',
      });

      const res = await checkQuestionAnswer(identifier, question.id, question.textAnswerInput);

      if (isApiError(res)) {
        console.error(res);

        dispatchQuiz({
          type: 'SET_ANSWER_EVALUATION_STATUS',
          questionIndex: index,
          status: 'not-evaluated',
        });
      } else {
        dispatchQuiz({
          type: 'SET_TEXT_ANSWER_RESPONSE',
          questionIndex: index,
          userPoints: res.user_points,
          totalPoints: res.total_points,
          responseText: res.answer,
          responsePictureLink: res.picture_link,
        });
      }
    }
  };

  const backLink = `/course/${getMainCourse(identifier)}/exam-training`;
  const backButtonHandler = mode === 'quiz' ? () => setConfirmLeaveModalOpen(true) : backLink;

  return (
    <Layout
      pageRef={pageRef}
      scrollContainerRef={scrollContainerRef}
      backButton={backButtonHandler}
      contentClassName='exam-paper'
      currentCourseType={identifier}
      disableTopNavigation
      additionalHeaderContent={
        mode === 'quiz' && (
          <div slot='end' className='exam-paper-header-infos'>
            <ProgressBar
              progress={getProgress()}
              className='exam-paper-progress-bar d-none d-md-block'
            />
            <span className='d-none d-lg-inline'>
              {(getProgress() * 100).toFixed(0)} % abgeschlossen
            </span>
            <span className='d-none d-md-inline'>|</span>
            <span className='remaining-time'>Verbleibende Zeit: {remainingTime}</span>
          </div>
        )
      }
    >
      <div className='exam-paper-container'>
        <h1>Prüfungssimulation: Fragebogen {examPaper?.nr}</h1>
        {mode === 'quiz' ? (
          <>
            {quiz.questions?.map((question, index) => (
              <Question
                key={index}
                courseIdentifier={identifier}
                question={question}
                questionIndex={index}
                onAnswerSelected={(answerId) => {
                  dispatchQuiz({
                    type: 'SET_CHECKED_ANSWER',
                    questionIndex: index,
                    checkedAnswer: answerId,
                  });
                }}
                onAnswerTextChanged={(text) => {
                  dispatchQuiz({
                    type: 'SET_TEXT_ANSWER',
                    questionIndex: index,
                    textAnswer: text,
                  });
                }}
                hideButtons
                onEvaluateAnswerClick={() => evaluateAnswer(question, index)}
                savedQuestionType='exam'
                onSaved={(saved) =>
                  dispatchQuiz({ type: 'SET_SAVED', questionIndex: index, saved })
                }
                hideResult
                pageRef={pageRef}
              />
            ))}
            <B1Button
              className='button-block submit-exam-paper'
              click={() => handleSubmitExamPaper('confirm')}
              disabled={evaluating === 'evaluating'}
            >
              {evaluating === 'evaluating' ? (
                <>
                  <IonSpinner />
                  <span>
                    Deine Antworten werden mithilfe von KI überprüft... (
                    {
                      quiz.questions.filter(
                        (q) =>
                          !q.evaluationStatus ||
                          q.evaluationStatus === 'evaluated' ||
                          q.evaluationStatus === 'not-evaluated'
                      ).length
                    }{' '}
                    / {quiz.questions.length})
                  </span>
                </>
              ) : (
                'Fragebogen abgeben'
              )}
            </B1Button>
          </>
        ) : (
          <>
            <div className='quiz-summary'>
              <h5 className='bold mb-4'>
                Dein Ergebnis:{' '}
                {examPaperPassed === 'passed'
                  ? 'Bestanden'
                  : examPaperPassed === 'marginal-passed'
                    ? 'Knapp bestanden'
                    : 'Nicht bestanden'}
              </h5>
              <div className='answers-summary mb-4'>
                <span>
                  {examPaperPassed === 'passed'
                    ? '🎉'
                    : examPaperPassed === 'marginal-passed'
                      ? '😬'
                      : '🤔'}
                </span>
                <B1Button className={examPaperPassed} disabled>
                  <span>
                    {categories.reduce((a, b) => a + b.correct, 0)} /{' '}
                    {categories.reduce((a, b) => a + b.total, 0)}
                  </span>{' '}
                  {(examPaper?.total_points ?? 0) > (examPaper?.questions_count ?? 0)
                    ? 'Punkte'
                    : 'Richtig'}
                </B1Button>
              </div>
              <div className='table-responsive mb-4 w-100'>
                <table className='category-summary-table mx-auto'>
                  <thead>
                    <tr>
                      <th>Kategorie</th>
                      <th>
                        {(examPaper?.total_points ?? 0) > (examPaper?.questions_count ?? 0)
                          ? 'Punkte'
                          : 'Richtig'}
                      </th>
                      <th>Benötigt</th>
                      <th>Gesamt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((category, index) => (
                      <tr
                        key={index}
                        className={
                          'category-summary ' +
                          (category.correct >= category.correct_to_pass
                            ? hasMarginalPassed(category)
                              ? 'category-marginal-passed'
                              : 'category-passed'
                            : 'category-failed')
                        }
                      >
                        <td className='bold'>{category.title}</td>
                        <td>{category.correct}</td>
                        <td>{category.correct_to_pass}</td>
                        <td>{category.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className='mb-4'>Benötigte Zeit: {timeNeeded}</p>
              <p className='summary-text'>
                {examPaperPassed === 'passed'
                  ? 'Prima, das läuft schon sehr gut! Die Prüfung hättest Du damit bestanden 😊'
                  : examPaperPassed === 'marginal-passed'
                    ? 'Das sieht schon ganz gut aus, in der Prüfung wäre es aber sehr knapp gewesen! Viel Erfolg weiterhin 😊'
                    : 'Vielleicht solltest Du diesen Fragebogen nochmals lernen, gehe dafür am Besten zurück in den Fragentrainer. Wenn es dort gut klappt, probiere es erneut!'}
              </p>
            </div>
            {showAnswers &&
              quiz.questions.map((question, index) => (
                <Question
                  key={index}
                  courseIdentifier={identifier}
                  question={question}
                  questionIndex={index}
                  showAnswer
                  savedQuestionType='exam'
                  onSaved={(saved) =>
                    dispatchQuiz({ type: 'SET_SAVED', questionIndex: index, saved })
                  }
                />
              ))}
            <div className='quiz-summary-buttons'>
              <B1Button
                className='button-reverse'
                click={() => {
                  dispatchQuiz({ type: 'RESET_ANSWERS' });
                  dispatchQuiz({ type: 'SHUFFLE_ANSWERS' });
                  setStartTime(new Date());
                  setMode('quiz');
                  scrollContainerRef.current?.scrollToTop(1000);
                }}
              >
                Test wiederholen
              </B1Button>
              <B1Button className='button-reverse' click={() => setShowAnswers(!showAnswers)}>
                Antworten {showAnswers ? 'ausblenden' : 'anzeigen'}
              </B1Button>
              <B1Button
                href={`/course/${getMainCourse(identifier)}/exam-training`}
                routerDirection='forward'
                unmountComponent
              >
                Weiter
              </B1Button>
            </div>
          </>
        )}
      </div>

      <B1Modal
        className='confirm-submit-modal'
        open={confirmSubmitModalOpen}
        onModalClose={() => setConfirmSubmitModalOpen(false)}
      >
        <h1 className='emoji'>🤓</h1>
        <h2 className='no-hyphens mb-5'>Möchtest Du den Fragebogen wirklich abgeben?</h2>

        <div className='w-100 d-flex flex-nowrap gap-3'>
          <B1Button
            className='button-reverse button-block'
            click={() => setConfirmSubmitModalOpen(false)}
          >
            <BsArrowLeft />
            Zurück
          </B1Button>
          <B1Button className='button-block' click={() => handleSubmitExamPaper('confirmed')}>
            Abgeben
            <BsCheck2Circle />
          </B1Button>
        </div>
      </B1Modal>

      <B1Modal
        className='confirm-leave-modal'
        open={confirmLeaveModalOpen}
        onModalClose={() => setConfirmLeaveModalOpen(false)}
      >
        <h1 className='emoji'>🤔</h1>
        <h2 className='no-hyphens mb-5'>Möchtest Du den Fragebogen wirklich abbrechen?</h2>

        <div className='w-100 d-flex flex-nowrap gap-3'>
          <B1Button
            className='button-reverse button-block'
            click={() => setConfirmLeaveModalOpen(false)}
          >
            <BsArrowLeft />
            Zurück
          </B1Button>
          <B1Button className='button-block button-red' href={backLink} routerDirection='back'>
            Abbrechen
          </B1Button>
        </div>
      </B1Modal>
    </Layout>
  );
};

export default ExamPaper;
